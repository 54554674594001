<template>
  <div class="heat-calendar">
    <svg width="660" height="120">
      <text class="day-initial" x="5" y="33" style="text-anchor: middle;">M</text>
      <text class="day-initial" x="5" y="57" style="text-anchor: middle;">W</text>
      <text class="day-initial" x="5" y="81" style="text-anchor: middle;">F</text>
        
      <g>
        <rect class="legend" width="10" height="10" x="513" y="102" fill="#c6e48b"></rect>
        <rect class="legend" width="10" height="10" x="526" y="102" fill="#629851"></rect>
        <rect class="legend" width="10" height="10" x="539" y="102" fill="#57904b"></rect>
        <rect class="legend" width="10" height="10" x="552" y="102" fill="#488542"></rect>
        <rect class="legend" width="10" height="10" x="565" y="102" fill="#2e7133"></rect>
        <text class="legend-text" x="471" y="110">Less ({{minCount}})</text>
        <text class="legend-text" x="580" y="110">More ({{maxCount}})</text>
      </g>
      <text 
         v-for="(m, index) in months" 
         :key="index"
         class="month-name" 
         :x="11 + (12 * m.week)"
         y="12"
         >{{ m.month }}</text>
      <rect 
         v-for="(d, index) in days" 
         :key="100+index"
         height="10" 
         width="10" 
         :x="11 + (12 * d.week)"
         :y="15 + (12 * d.day)"
         :fill="color(d.count)"
         @mouseover="dayOver(d)"
         @mouseout="dayOut()"
         ></rect>
    </svg>
    <div class="onhover" v-html="hoverInfo"></div>
  </div>
</template>

<script>

export default {
  name: 'HeatCalendar',
  props: {
    data: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      dayStart: null,
      dayEnd: null,
      hoverInfo: '',
      days: [],
      months: [],
      minCount: '',
      maxCount: '',
    };
  },
  mounted() {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const d = new Date();
    this.dayEnd   = parseInt(d.getTime() / 86400000);
    this.dayStart = this.dayEnd - 366;

    const days = [];
    let week = 0;
    for (let d = this.dayStart; d <= this.dayEnd; d++) {
      const time = d * 86400000;
      const _date  = new Date(time);
      const date = _date.toISOString().split('T')[0];
      const day = _date.getDay();
      const data = this.data.find(d => d.date === date);
      const count = data ? parseInt(data.count) : 0;
      // new week ?
      if  (0 === day) week++;
      
      // new month ?
      if (1 === _date.getDate()) {
        this.months.push({ 
          month: months[_date.getMonth()],
          week
        })
      }

      days.push({ date, day, week, count });
    }
    this.days = days;
    this.computeMinMax();
  },
  methods: {
    color(count) {
      return `hsl(89deg ${3*count}% ${90 - 3*count}%)`;
    },
    dayOver(d) {
      this.hoverInfo = d.count
        ? `<strong>${d.count} Changesets</strong> on ${d.date}`
        : `<strong>No Changeset</strong> on ${d.date}`;

    },
    dayOut() {
      this.hoverInfo = '';
    },
    computeMinMax() {
      let min = Infinity;
      let max = 0;
      this.data.forEach(d => {
        if (d.count < min) min = d.count;
        if (d.count > max) max = d.count;
      })
      this.minCount = min;
      this.maxCount = max;
    }
  },
};
</script>

<style scoped lang="scss">

.heat-calendar {
  text-align: center;
}

.onhover {
  height: 15px;
  font-size: 11px;
  margin-top: -20px;
}

text {
  font-size: 10px;
  fill: #aaaaaa;
  font-family: Helvetica, arial, 'Open Sans', sans-serif;
}

</style>
