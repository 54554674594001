<template>
  <div id="page" class="pad">
    <section>
      <h2>Ways &amp; Buildings</h2>
      <div v-if="stats" class="stats">
        <div class="stat">
          <table>
            <caption>way [highway=residential]</caption>
            <tbody>
              <tr>
                <th>Total Count</th>
                <td style="width: 100px">{{stats.highways.residential.count}}</td>
              </tr>
              <tr>
                <th>Total Length</th>
                <td style="width: 100px">{{Math.round(stats.highways.residential.totalLength/1000)}} KM</td>
              </tr>
              <tr>
                <th>With a Name</th>
                <td style="width: 100px">
                  {{stats.highways.residential.hasName}}
                  ({{(Math.floor(100*stats.highways.residential.hasName/stats.highways.residential.count))}} %)
                </td>
              </tr>
              <tr>
                <th>Arabic &amp; French names</th>
                <td style="width: 100px">{{stats.highways.residential.hasBothNames}}</td>
              </tr>
              <tr>
                <th>Arabic Only names</th>
                <td style="width: 100px">{{stats.highways.residential.hasArabicOnlyName}}</td>
              </tr>
              <tr>
                <th>French Only names</th>
                <td style="width: 100px">{{stats.highways.residential.hasFrenchOnlyName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="stat">
          <table>
            <caption>buildings</caption>
            <tbody>
              <tr>
                <th>Total Count</th>
                <td>{{stats.buildings.count}}</td>
              </tr>
              <tr>
                <th>with "building:levels"</th>
                <td>{{stats.buildings.hasLevelsCount}}</td>
              </tr>
              <tr>
                <th>with "building:color"</th>
                <td>{{stats.buildings.hasColorCount}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>Loading...</div>
    </section>

    <hr>

    <section>
      <h2>Other Tags</h2>
      <div v-if="stats" class="stats">


        <div class="stat" v-for="(tags, tag) in stats.tags" :key="tag">
          <div>
            <table>
              <caption>{{tag}}</caption>
              <tbody>
                <tr v-for="(t) in tags" :key="t.v" class="icons">
                  <th class="icon"><img v-if="icons[tag + '-' + t.v]" :src="icons[tag + '-' + t.v]"></th>
                  <th>{{t.v}}</th>
                  <td>{{t.n}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>Loading...</div>
    </section>

    <hr>

    <section>
      <h2>User Stats</h2>

      <div v-for="user in users" :key="user" class="user-stats">
        <div>
          <strong>{{user}}</strong><br>
        </div>
        <HeatCalendar v-if="calendar[user]" :data="calendar[user]" />
        <div v-else class="user-calendar-loading">Loading calendar ...</div>
      </div>
    </section>
  </div>
</template>

<script>

import ICONS from "@/assets/icons";
import HeatCalendar from "@/components/HeatCalendar";


export default {
  components: {
    HeatCalendar
  },
  data() {
    return {
      stats: null,
      icons: {},
      users: ["selimachour", "Anis Ben Makhlouf", "Ghassen Aouinti", "kleiner knopf", "firasfilali"],
      calendar: {}
    };
  },
  mounted() {
    this.$api.get('/db/stats').then(j => {
      this.stats = j.data;
      this.loadIcons();
    });
    this.users.forEach(user => {
      this.$api.get(`/osm/userStats/${user}`).then(j => {
        this.$set(this.calendar, user, j.data.calendar);
      });
    })
  },
  methods: {
    loadIcons() {
      for (let tag in this.stats.tags) {
        this.stats.tags[tag].forEach(t => {
          const id = tag + '-' + t.v;
          const icon = ICONS[id + '-15'] || ICONS[id];
          const size = 15;
          if (icon) {
            this.$store.dispatch('getIcon', { path: icon, vue: this}).then(j => {
              const {paths, viewBox} = j;
              const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}px" height="${size}px" viewBox="${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}">${paths}</svg>`;
              this.icons[id] = 'data:image/svg+xml;base64,' + btoa(svg);
              this.icons = { ...this.icons };
            })
          }
          return '';
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">

.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px;
}

h2 {
  text-align: center;
}

hr {
  margin: 2rem 0;
}

caption {
  background: #103073;
  position: sticky;
  top: 0;
  color: white;
  line-height: 2.5em;
  font-size: 1.1em;
}

.stat {
  flex: 1;
  max-width: 400px;
  
  & > div {
    max-height: 33vh;
    overflow: auto;
    border-bottom: 1px solid #aaa;
    border-right: 1px solid #aaa;
  }
}

table {
  font-size: 14px;
  table-layout: fixed;
  min-width: 300px;
}

th {
  white-space: nowrap;
  text-align: left;
  padding-right: 10px;

  &.icon {
    padding: 0;
    width: 25px;

    img {
      width: 100%; 
      background: white;
      border: 2px solid white;
      outline: 1px solid #9e9e9e;
      vertical-align: middle;
    }
  }
}

td {
  white-space: nowrap;
  text-align: right;
  width: 65px;
  padding-left: 10px;
}


.user-stats {
    display: grid;
    grid-template-columns: 200px 1fr;
    max-width: 800px;
    margin: 0 auto;
    height: 120px;
}
.user-calendar-loading {
  font-size: 200%;
  font-style: italic;
  color: #888;
  line-height: 120px;
  text-align: center;
}
</style>
