<template>
  <div id="page" class="aside-main">
    <Modal v-if="!hideModalHelp">
      <template #header><h3>Welcome to the StyleEditor</h3></template>
      <p>
        Cette section du site vous permet de créer vos propres styles de cartes à la
        <a href="https://studio.mapbox.com/" target="_blank">Mapbox Studio</a>, 
        la différence étant que les tuiles verctorielles (MVT) produites par Mapbox
        ne contiennent pas les données brutes d'OSM mais sont unifiées, filtrées ... 
        et il manque malheureusement beaucoup de tags telsque "color", "width" ... <br>
        L'autre problème est que les tuiles ne sont mises à jour à partir des serveurs 
        OSM qu'une fois tous les quelques semaines.
      </p>
      <p>
        Les tuiles utilisées ici proviennent directement d'une copie de la base OSM 
        (Tunisie seulement) mise à jour quotidiennement. <br>
        Tous les tags ne sont bien sûr pas inclus pour ne pas produire des tuiles trop 
        lourdes mais peuvent être rajoutées sur demande.
      </p>
      <template #footer>
        <button @click="hideHelp">Ne plus montrer</button>
        <button @click="hideModalHelp = true" style="float: right">Cacher</button>
      </template>
    </Modal>
    <pre v-if="showStyleJson" id="json">{{style}}</pre>
    
    <!-- Layers List -->
    <aside id="layers">
      <button @click="deleteLayer">-</button>
      <button @click="newLayer">+</button>
      <button @click="load">Load</button>
      <button @click="save">Save</button>
      <button @click="showStyleJson = !showStyleJson">j</button>
      <input type="text" v-model="layerFilter" placeholder="Filter layers ...">
      <ul>
        <draggable v-model="style.layers">
          <li
             @click="currentLayer = (currentLayer === layer) ? null : layer"
             v-for="layer in filteredLayers" :key="layer.id"
            :class="{ current: layer === currentLayer }"
            >
            {{ layer.id }}

            <Icon 
              :icon="layer.layout.visibility === 'none' ? 'eye-slash' : 'eye'" 
              :title="layer.layout.visibility === 'none' ? 'show' : 'hide'" 
              style="float:right" 
              @click.stop="toggleLayerVisible(layer)"
              />
          </li>
        </draggable>
      </ul>
    </aside>

    <!-- Layer Editor -->
    <aside v-if="currentLayer" id="layer">
      <LayerEditor :layer="currentLayer" @layerChanged="layerChanged"></LayerEditor>
    </aside>
    
    <!-- Clicked features -->
    <div id="features" v-if="clickedFeatures">
      <div v-for="(feature, index) in clickedFeatures" :key="index">
        <table>
          <tr v-for="(val, key) in feature" :key="key">
            <template v-if="key === 'layer'">
              <td>Layer</td>
              <td title="Edit layer" @click="currentLayer = getLayer(feature.layer)" style="color: cyan; cursor: pointer">{{feature.layer}}</td>
            </template>
            <template v-else>
              <td>{{key}}</td>
              <td>{{val}}</td>
            </template>
          </tr>
        </table>
      </div>
      <span @click="clickedFeatures = null">[close]</span>
    </div>

    <main id="map">

      <div id="info"> [bbox:{{bbox}}]&nbsp;&nbsp; Zoom:{{zoom}} </div>
      <MglMap 
        :accessToken="accessToken" 
        :mapStyle="style" 
        :attributionControl="false"
        :attribution="false"
        :hash="true"
        :tileBoundaries="true"
        @load="onMapLoad"
      >
        <MglFullscreenControl position="top-right" />
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
        <MglAttributionControl position="bottom-right" />
        <MglScaleControl position="bottom-left" />
      </MglMap>
    </main>
  </div>
  
</template>

<script>
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl
} from "vue-mapbox";
import { AllStyles } from "@/mapbox/styles";
import LayerEditor from "@/components/mapbox/LayerEditor";
import { addMobilePitchGesture } from "@/assets/p";
import MyControls from "../mapbox/controls";
import Reference from "mapbox-gl/src/style-spec/reference/latest";
import Modal from "@/components/Modal";

import draggable from 'vuedraggable';


export default {
  components: {
    MglMap,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl,
    LayerEditor,
    draggable,
    Modal
  },
  data() {
    return {
      hideModalHelp: null,
      accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2pjZW0weGoxMWZkZDJ3cGVmYmZkbHVreiJ9.0dFhFtJJUEN2ziihdjzNwQ',
      clickedFeatures: null,
      showStyleJson: false,
      zoom: 0,
      bbox: '',
      lat: 0,
      lng: 0,

      Reference,
      props: {},
      typeProps: { layer: {}, paint: {}, layout: {} },

      currentLayer: null,
      layerFilter: "",
      style: AllStyles['WhereStreets'].style,
    };
  },
  created() {
    this.hideModalHelp = localStorage.getItem('StyleEditor:hideHelp');
  },
  methods: {
    hideHelp() {
      this.hideModalHelp = true;
      localStorage.setItem('StyleEditor:hideHelp', true)
    },
    onMapLoad(event) {
      const scope = this;
      const map = event.map;
      this.map = event.map;
      this.load();
      
      // for debugging in browser
      window.map = event.map;

      // faster scroll with mouse wheel
      map.scrollZoom.setWheelZoomRate(1/100);

      // add myControls
      map.addControl(new MyControls.StyleSelectorControl({ styles: Object.values(AllStyles) }), 'top-left');
      map.addControl(new MyControls.LanguageSelectorControl());
      map.addControl(new MyControls.ExternalEditorsControl());
      map.addControl(new MyControls.ShowTilesCoordinatesControl());
      addMobilePitchGesture(map);

      if (map.getCenter().lat < 0.01) {
        map.fitBounds([[0, 29.25], [19.8, 37.98]]);
      }
      
      /**
        * Display Mouse Coordinates
        * Retrigger Search
        */
      map.on('mousemove', function(e) {
        let precision = Math.round(map.getZoom() / 3);
        scope.lat = e.lngLat.lat.toFixed(precision);
        scope.lng = e.lngLat.lng.toFixed(precision);
      });
      map.on('zoom', () => {
        this.zoom = this.map.getZoom().toFixed(1);
      });

      /**
       * inspect features
       */
      map.on('click', function(e) {
          const features = e.target.queryRenderedFeatures(e.point);
          const display = features
            .map(f => {
              return JSON.stringify({
                layer: f.layer.id, ...f.properties
              })
            })
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(j => JSON.parse(j));

          scope.clickedFeatures = display.length ? display : false;
      });


      /****************************************************************************************************** */

    },

    // transform mapbox specs into flattened specs
    // @todo only layers for now
    loadSpecs() {
      const props = {};

      // layer props
      Object.keys(this.Reference.layer)
        .filter(v => !['layout', 'paint'].includes(v))
        .forEach(v => {
          this.typeProps.layer[v] = this.Reference.layer[v];
          props[v] = this.Reference.layer[v]
        });
      
      // paint and layout properties
      Object.keys(this.Reference).forEach(group => {
        const m = group.match(/^(paint|layout)_(.*)/);
        if (m) {
          Object.keys(this.Reference[group]).forEach(v => {
            if ('undefined' === typeof this.typeProps[m[1]]) this.typeProps[m[1]] = {};
            if ('undefined' === typeof this.typeProps[m[1]][m[2]]) this.typeProps[m[1]][m[2]] = {};
            this.typeProps[m[1]][m[2]][v] = this.Reference[group][v];
            props[v] = this.Reference[group][v]
          })
        }
      })

      this.props = props;
      this.typeProps = Object.assign({}, this.typeProps);
    },
    newLayer() {
      this.style.layers.push({
        id: 'id-' + parseInt(Math.random() * 1000),
        type: "line",
        source: "composite",
        "source-layer": "roads",
        minzoom: 0,
        maxzoom: 24,
        filter: ["all"],
        layout: {},
        paint: {}
      });
    },
    deleteLayer() {
      this.style.layers = this.style.layers.filter(l => l !== this.currentLayer);
      this.currentLayer = null;
    },
    layerChanged() {
      if (this.map) this.map.setStyle(this.style);
    },
    load() {
      const saved = localStorage.getItem('editedStyle');
      if (saved) {
        this.style = JSON.parse(saved);
        this.layerChanged();
      }
    },
    save() {
      localStorage.setItem('editedStyle', JSON.stringify(this.style, null, ' '));
    },
    getLayer(layerId) {
      return this.style.layers.find(layer => layer.id === layerId);
    },
    isLayerVisible(layer) {
      return layer.layout.visibility !== 'none';
    },
    toggleLayerVisible(layer) {
      if (layer.layout.visibility === 'none') 
        layer.layout.visibility = 'visible';
      else
        layer.layout.visibility = 'none';

      this.layerChanged();
      return false;
    }
  },
  computed: {
    styleJson() {
      return JSON.stringify(this.style, null, '  ');
    },
    filteredLayers() {
      return this.style.layers.filter(l => l.id.indexOf(this.layerFilter) > - 1);
    }
  },
  watch: {
    style: {
      deep: true,
      handler() {
       if (this.map) this.map.setStyle(this.style);
      }
    }
  }
};
</script>

<style>
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
</style>
<style scoped lang="scss">
#mapWrap {
  position: absolute;
  left: 0; right: 0; top: var(--mainMenuHeight); bottom: 0;
  display: flex;
}
aside {
  overflow: auto;
  &#layers { 
    button {
      font-size: 10px;
      cursor: pointer;
    }
  }
  &#layer { 
    background: #151619 !important;
    width: 360px !important;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      background: #293122;
      padding: 1px 2px;
      margin: 4px 0;
      cursor: pointer;
      &.current {
        background: #ae0000;
      }
    }
  }
  background-color: #0c1b00;
  color: #eee;
  font-size: 12px;
  padding: 4px;
}
#map {
  flex: 1;
  position: relative;
}



/* Inspector */
#features {
    position: absolute;
    font-size: 12px;
    top: 13px;
    right: 54px;
    width: 300px;
    overflow: auto;
    background: rgba(0, 0, 0, 0.8);
    color: whitesmoke;
    z-index: 8;
    box-shadow: -2px 3px 7px -3px rgba(0,0,0,0.8);
    max-height: 90%;
    padding: 10px;

    & > span {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      color: white;
    }

    table {
      width: 100%;
      margin: 20px 0 10px;
      background: #000;

      td, th {
        padding: 2px;
      }
    }
}
#info { 
  position: absolute; 
  bottom: 0; left: 160px;
  background: hsla(0,0%,100%,.5); color: rgba(0,0,0,.75);
  padding: 2px; 
  font-family: 'monospace'; font-size: 12px;
  z-index: 1;
}
#info:hover {
  background: hsl(0,0%,100%); color: rgb(0,0,0);
}

.map-overlay {
  font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  position: absolute;
  width: 25%;
  bottom: 100px;
  left: 0;
  padding: 10px;

  .map-overlay-inner {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin: 0 0 10px;
  }

  input {
    background-color: transparent;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0;
    cursor: ew-resize;
  }
}

pre#json {
    position: absolute;
    top: 30px;
    width: 800px;
    left: 50%;
    bottom: 30px;
    z-index: 99;
    background: white;
    color: black;
    overflow: auto;
    box-shadow: 0 0 5px -2px black;
    margin-left: -400px;
}

</style>
