<template>
  <div id="page" class="aside-main">
    <aside>
      <ul>
        <li v-for="(cat, i) in links" :key="`1_${i}`"><a :href="'#' + cat.label">{{cat.label}}</a></li>
      </ul>
    </aside>
    <main class="pad">
      <div class="cat" v-for="(cat, i) in links" :key="`1_${i}`">
        <h2 :id="cat.label">{{cat.label}}</h2>
        <div v-for="(item, j) in cat.items" :key="`2ç${j}`" class="item">
          <img :src="item.icon">
          <a :href="item.url" target="_blank">{{item.label}}</a>
          <span v-if="item.desc" class="desc">{{ item.desc }}</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          label: "Raster Maps",
          items: [
            {
              label: "OpenStreetMap",
              url: "https://www.openstreetmap.org/#map=13/36.4924/8.8044",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADZElEQVQ4jWXSS2yUVRjG8eec881815nOdGbSwlgt0yYtGCQikjAQgxtWRmPQboxuZOGm6sr1bFwY48YEFyyIy3agiTeQgAGtsUQjLWmCSmOhLZSx7dw6l+9+zuvChBj9rf6rZ/Ww253qiTjAWSJ1G0E8YyRzm6ZlHWt53dMb7bVywogXdNeY7bb1+eFC/nnHZq/Yll1aurJzfGpqKmQLD6pmwqBbmbSzv7HRkFY+H3IuzGbQQ6OzjaQ0YMUjGDCHehHFTsevw7EClEaz5SF2/CYvj0x5YRTNRTKOpReQ122ZUegpGboyaBq0Vy/LfcVJlSuknXTaVJnUsN91MzQ/v/YCAHAAgKfVkpqj6ZkBYq5SEDHz6p7IG/tQyNuCcckYCTKSBoPuJ4RFkDLzWoWIawAwmM2WDWHBcBQY+Wy9dZ+t7kiUS6MsjkzImDNOGtw4RCfYYrFwoacSk2vvfDCm/dqsDkgWn+y4bUgvELtekzWkj7w9Dj900Hc5FAFBJNH3BcAHGFlNKI10XceYprruE55OOS4F0AuYSjLakxxmjzba6Oo+rIwH8B76bg6KkqQbXXLMLLdt4e5uN5b5xx99vt533TWoGKEMlJlwmMmGITGIn3+8gZgJEHdhOTXYmU3kUgZNDB6g1ubuhZmZ83V+4bPve+16Z1G3dJCKVOgLMngWKcPBd1/M4kr1G8ShA6ZpSJs22aqAP/5cwWpt8TqAWAMAKeXvUhH8RBqOJLo6M8ta9+5jdFxD11vBb8sWbN2EYZssklImk4yPPOlMAlAaADxVPLDXEClkC13Mnb/Mrs4sUUIPcea9t/D0s0U0ezX06x3Udlrw7JCPpiextRo+BABtenpa97XoVFL1aPbsJf5t9SYJjeill4+y8f1F7qm8HMoCWo6xu517qqk6aMQP/WuXb1wHAO3gq8VxP+6PzX54CdfmfmLZgsXfeP8UjkyU0BNtlc0nhWB7ELMtRFHMc2YGzc3dR1LfrQGAljL1/vrSyrnW6srbigXi0IlDNdNOLF786ocvf1lYXj395sljuULpxVRGL9X+2l7zw/DOgzvbF78+d8vFvxjvnil/euTowU8mDh9+Bv/HU6lU7vH1/4NVq68LoJjLlp4bAIAqVQVRhaMC/k8TB4AKVThRVVQqlcdDfwOK6qV7BRBy3AAAAABJRU5ErkJggg==",
              desc: "La carte officielle du site officiel"
            },
            {
              label: "OpenWhateverMap",
              url: "http://openwhatevermap.xyz/#13/34.7470/10.7402",
              desc: "Site très sympa qui montre plusieurs styles OSM en même temps."
            },
            {
              label: "Michelin",
              url: "https://www.viamichelin.com/web/Maps?address=La%20Marsa%2C%20Tunisia",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADIUlEQVQ4jYWSS2hcdRSHv/O/d2Zu5pE0ViXFXo2aSLsoKihEqIIUIZoSpV1YSilUdGE30UqsuKgSEAIVSmkzhBYtQpr6wCqmSgtJ2oGI9pH6INBkYZuNjZ0kQ0w7zJ37+B8XiY+d3/qcD37n/KSpGDykcEhUugADgLVijINJpUjCAEUQERSUFayKfivwpqvIIeOlu7UWJaCQJOJmGwiCOtW5GzSvexCiEFVFRGRFIhgv3W2DEGkaqCcgCmo0ScTNN1CZ/pn7Jw/TvSFH8fcHyD7fixvVUHFUVodBLKgYAfP3spNrYOn7r9h6/UPOHXydI0ePcuRpi555D5v2wFpRUAEBNQLGKKiq4nppvX3jmnb+eVpHTh0nteZeFhYWde++/fRuquvyxa9xc55ik38kCmpQFbGxkHHETp2V17ZtkVA8Pv/0FDPT1+T4Ryd48vFN4ldnCCJWT6miqgiIQRVxXI1rEfxWUi9b0Mrigg4ODqrjujo8fFLnyvN6TzogDCIV46AKIgKAQS1ONiPLox/z9nM+nS9ulzisy8TEhHR0dMi77+yna9sOdj+aI756GmlIIySornzUCEqUKLmlaV7dswdrLUEQ6OzsrI6NjeF5Hg25gryyawfNi79KlIDwbyGM2gTHqNadPJd+mMAYQ1tbm7S2thLHsRYKeZoKOX65/CPVSNQRy2p+VBXXNDaCi2S7etn5/rNSXq6xt+ctfN8X3/cBOFYc4MDQBexLA+JmHOJ6Ck1ixDi4S2cOa6q6IEnlum7v3IyTysitP27p+vX3Ya2VuZs39bvR87L24cfQK8coczfeU7s025wXDRKVL4ZO6F1rGrVUKkniZPRA3weCKsYYRIQoivG8DOHyPIvzZc6NnteDn5VkpvkZTW3eKeh/GBo6qf39/Vb/h/GzI3bd2qb4iRdeTmRqamokm81u9X0/cV3X6evrw/d9WlpaCMM6SWIJw5BarcadO7cJaoFevvqTfaOnx/ly+JNvnGKxeHF6ZqZ1cvLKI+3t7VKpVBgfH5dUKkW5XKZarRJFIa7jki8UdMPGjQS1mhpxRjq2dO37C79Uu2zK0Pk6AAAAAElFTkSuQmCC",
              desc: "Qui se souvient des cartes papiers Michelin ?"
            },
            {
              label: "HERE WeGo",
              url: "https://wego.here.com/?map=36.88987,10.33127,13,normal",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACg0lEQVQ4jZWSS0jUURTGv3PP/TvO6GRYQTYRhYFEQYVoPhaaj9JliEaEtYmWraJ1y3btiiCCIiJMMqlVOCTUiI8GhdJSSqvJIqcwe4wz/7n3nhY69MBev9V34Hwfh3MOsAThO+o3OgetWOypaeh0IBkZiF5dNgoA2VldHQkgVKHYvR18cG/oB5/kBFXVNByF0peIQM7aQ0MD0esAUFHbeFArPicixQDECe4svE8dnpyMfc6FEADeU9t4W3t5rSZrfMWKnbFti19MPLTKmwaRBycQiNXaY2tNdD6ZOpALUQCsRvqg9bNRZs4TEYDoSjDsXQbgOWtFIC+JiI3JGqW4cfXa0K2ystowAGEASCQSfkGopCc/pCuV4lIRp4lUqVIsTtw1m/7Ywpw/S0q1iljLrLcGgrq8cFNJb27LNDkZ+zyfTB1wzkZZa3bivhIRAViMx+OpwYG+886aI0SsrLU+M+9bTaE+/vEiHz4kMgXBkp78oK5k7ZVZY3xFXL4hsuXZ7OvpR7OvZx5HNm6eIqU6ACEHuUjtIpxEPwHA3I2kmmhvt9Udx4ok+eYmk6qzWT9DROKs7RwaiHYDQGVV41EwFQ/H+s7+9BS/UrGjqjuwbk0blhYLk8mc+PTxzYWJiQk/N7XePzV6Smmv0Ph+lpiVAiB+VnRxEWdfJGILJ888BCSPiJRVKAqHw/kAzHKA00KYBfPVUMl62MU0AAEXFMCfS/oUiTQNxe7e/9OUBADNT0drdTDQC2PDIDARvcqkTUt0264plB/36uNTgnqgvx8A+s1PCe2y9AtNT0bqW2fG0y3PH73bOz62HQDQ1cX4J5Ybm58M1zWNj+3+P3OO06fVivovfANwlB4AXhgHZQAAAABJRU5ErkJggg==",
              desc: "by Nokia."
            },
          ]
        },
        {
          label: "Vector Maps",
          items: [
            {
              label: "Qwant Maps",
              url: "https://www.qwant.com/maps/#map=11.19/36.8795544/10.2631441",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADIElEQVQ4jW1Ta2jVdRh+3vf3+//PzmV5XNPD1HNsbLPpcF0OyuYNEyPcCDJKiVHkl2CQQheib52KBlEgXUBOUIk6YglGF7Y+JMFAWKk1IlOGsbO5pZt4zs5l5/K//N4+OGJqz7cX3ud93ud5eQl3oZKJNdsqcJCNv6esI6vm3ShuuuHrt5zQaFGtHDrQfnJyeT8tL/x/1r3JkFcNrFXny23401mHognC9wUsBkI0fwu7Ph546Ln3AfIAQImAdxPUFydjJzhEr5SKgfBQaQfG8vEFx1eXje/PG98ERdt1VLsRdu/btqelv61txTP0bab559sbyFRjCvX6LT/H7lB5h/dHbe0HrdbM8fNjp2eAJOLJZEKLfahAD7+eVY+xjq633HL+7c867k+RTEU2GQR/YeOF/jIby59mdx84tmVg5O5sAGDvoDzd9EDpVDCyaIOtioG9leHaLzJJRKwQX68Ejh7bMjCSvpC0IMvyEdBL6bT1Ux+dmZtwP6qWwkpZFDF+5QWSayvG4FtbHbFLv1VXd3a1j08BABHkTn0hCPB4utBq13u/N3VwSGnvHFcnYjE0utBFnraOqlkiyL1kACABkejEuWnfwWw5p0GMmM6924um7lHwpQaV/C5KwMX/s/8fFkqbOUrQtRJgVpPH03O7ZjASJ8zZ69GVaxGABOB7qUKAUMQNPEjMa33XwDg0yZNu59kpvQ2oywcNRw4TIMCzJAAvDSMAS6ZIbFKHWesAKybAH+Y6zzk+XtiXvVbcaVhl+6V7+8uE0z4BhpZkb9+BpGcwfwTQh8R44rvOJWmsfcX7MxszJOadK4v9fGGhzylIyyey/YlB6dqwTx7Bmr83HUl83fFjAwAw0U5iRWwH4Fdx9vN4PEsppDiFlPlhw/iHTA2vuZJDzJ6FRs4smuDNmomFF01wdP/Eo709X2Y3k61+FeNarPSNkvG77nim4daLfcTBN2rQ7URsawAED0EOII9i71OXO4efPJU7oSLR52EMnHLxjF6ecs9VGkyv+f6b5vrE3qpUul3hhBHUjKmbhK0yAMitee8JFeC7VSativ8C/gdf6jLRm1QAAAAASUVORK5CYII="
              },
            {
              label: "Google Maps",
              url: "https://www.google.com/maps/@36.8956224,10.3217119,12.25z",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACt0lEQVQ4jY2TXUhUQRiG32/OnLMej7uuBZWGZlE3ZpR1YSD9ieBdZRQUWVFRQvR7Y0K0rFDURRlhUEEEQdiv6FWRFRQEdaFlQUV6IxRG9KPhuu6eMzNfF7uKlUHfzczFvO8838z7ESYVAwQAMw4MLJF27nYCLwrYWLNynZeFIautKxbtAZgA4nENTdITwCQa0/sLOXECViisgjQ8m1BakA9iPar8ZOxprKAlq2MAEAAQz6z8ZU1l/e6fD88PCS+s/KTKy3FROm0aSKWU0coLedGz1THemRGz+I2gv3J+pCA38s4TujBWtI/bZqxTxXluh+dYyjBvIUEgeCIVbv84VL1x4buFSACA4CxF1I2sFFLOThgLscFLOD12cu+L5vwtj4/lbYNM7xLsWencRyZdeK3EebN2FTLoQmITCHcAElScI8gkjC14zafP9cdbbyUX77UBoKFh+u0VZ3pOpWZeLSI7ZCxFJRnu1ULiTmarLZPwR4XIqRo0eTWD0eR9u6yh4XIvQFxzZW75sPch31DSWDpEbIIUAODtExYADADogXA3yr/5Xu1Hg0C4jsO3uIt2fH0Y3kORZ+1GJjwLktnXJjCmGwBwG4YAgBlEBE4/kB1OhNcHIxTYDmxIjeaRStwdmQNX+4pcW+qU7urZ3F47/pVicpAcSx01SSTIgQVfofV7ub4+VKpd4ytYFpnAjCmJpuwD0kQOiMDMIKpBn69wSNpKdPjz1MXRMooKnxgAOZZlfNX4emP7KwCEeKb1CQIicDwO4dbqq/d+FLecCypkiDQT2AjXkSoV3Hy5tfMC4pnQTRXl8YEgHOx3llY1PZeSKgzA0NynHL28t65z+M/j4i+DZhC3LgiYgsOGoUGADvSR3rrO4ezt/1GcIVvWVte97MaG9/+kBSCnNGjOTpugSzBwsqZTWvwC9FkjnHrnD/UAAAAASUVORK5CYII="
              },
          ]
        },
        {
          label: "3D Maps",
          items: [
            {
              label: "F4map Demo - Interactive 3D map",
              url: "https://demo.f4map.com/#lat=36.8023959&lon=10.1884135&zoom=17&camera.theta=65.103&camera.phi=134.359",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADO0lEQVQ4jX2TXWxTdRjGn/d/ztnpB13XdXYbPevmvhwp6gQuZgBZ1CELBKcFZoKYJRBi4pYsXhhjpjkarogxZDReGAUTmAYucIFExGDcItFGNmwyBqTrGFtlH7DAGe22rqc9rzebQc187t48+T3v1Q9YJbqui1Uq53u7N7tWDnk1WNd1C8gLHjq0/yWvu7C0xO8v9XnzS2NTycof+65cZeBNAqz/DDCzICJL07RQONx9UquodWVZwGLC/Nwchr3lkGOP1hyr/ykfUcN4fICYmYjICgbrO4+HPzt6K7GovB36eOmBxSLDDMwnqendLipYW/1E5HagDjAi0jIslj/zthdf7u45deKjjRs3SalUMhcKNcr7X20QiaQpxsenha9Ms4rKq+SH8YFro6OjAwKAxGfPEhEVtIT2nvvm6y86pgzTHLg1kvOVeEWgZA1GFxR4Nuxg4XTg7u0Yq+5iVrzlzzFAAkCO9u0re6e944dvvwq3fH7+prmtvVcK/3xPjAsfBpMO/BabJpurgPy1NXw3PiLSGRNS0ZP1aKxQJYcjv7mzo/3Mh10fBDuPXTS7L6ckofkpGhlGTi3Aji1VRGaax+6bIBDNjFzHui3bhWnMOBd86TNSTe26T1vbO54/ce6XzJf9liKtLRIWExOnxPXhSUoY4OaGAOIT02BbIQLVlSgqr+OFufvq4kysT1KyS7/ay6r2bN25y3Ppyg0ro3gInCUIBSJnID6RsRL3UlRbLNG8XAi3r5hu9F+w3Fq1cC5NzkjJdHpuMj4yvf213XtUCVb0piHIoQKSyrz4EHAXSuOzQPzOVDb4lMZjQ4MiEk1k654OCnvWgKTrurhwvndIVmzrWw+8vv5i31AuTS4BWQbSj0Te2OU/WM3LT5l2mzL/p4gNRWnBs0n20CwtTlz9hACQrjPpOvmPf/f9QCLp9B49fScLt0OtTPT8Hp7tbWqJI+Cq2XzY47I/47ajazCl7fTx1LPvP7gU+oc4TW+0HTjVH+GKth72NbyVPn2krQF7WQIABgSfbLT97QsgA0yPa0AA8ELzrtYNW1+5dvjgwSPMLAMQy92KnQLQxb/hFZEIAJoBdYzZtjL6f/kLqjlYlfhZEdkAAAAASUVORK5CYII=",
            },
            {
              label: "Berlin (DE) • OSM Buildings",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVQ4jWNgoBAw4pP8viltJjKf029WOroaJkpdQLEBRIHvm9JmonsHBlg+zzQ+g08zb/pZE3zyAx8GjOheIORkdMCCTfBDA8N/Yg0Y+DAYeAOwZiZSApFiAABbYBZKuqLvlwAAAABJRU5ErkJggg==",
              url: "https://osmbuildings.org/?lat=36.80518&lon=10.18188&zoom=16.4&tilt=45&rotation=130",
            },
            {
              label: "Simple 3D buildings - OpenStreetMap Wiki",
              url: "https://wiki.openstreetmap.org/wiki/Simple_3D_buildings",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADIElEQVQ4jX3JzW8UZQDA4d8778zszO7sTqe7FloobWn9SCSgNMbIhTapJnrw5BETDz145So3/g5OhniQNBoRjSBJI5JGTFpbSNqYFGS7FNhud3e2uzvf83rxSHyujxjEnXcf99e+Lkp3c0Seum6Xqo/i+Oj9dvT8o53W1oJjOjtvjpxdcUrjd9JoeL4fHXwc078wXXlvXgihBMDDw58eBql/RvkJVnWUNId20GOYDEhbObaapGh6xFnMIO1S9RTzpxfmS+bIug5Qlsd+HsT+GRWlBP0ehWIJnZxOPeP86QXskgGaIohCgqDM3ssmTxuND4B1DcAQzj8FvYisVRBhTpwGxH7EVPUc5YqFJiWWYeOWXPQimEXFztP9ZQAdIBH9Dwt6EUmCsAzqg2fs7g1YPDeFygrkGQShSZLHdMMY3RHkqfZOo9F4Q1NKiUHSupjlEWkc0gs6dOMYW40RhCZBJEhTyTDO6PYVInURQmCWJEdhWNODIDg5jH1PSknmD0nLUEur1LOQMMkx7TZoEb2BR5oaFJwhUthoiZ3VXPeJZtt2K0/EkzAekuUJlrAoGmUse4z7q7cZhAmpCiiWn1Px9nHtArPuLEk7viWlHGpAngfWA4QATZCmGno6iqU7rN68wfr9TZSSgMIydEakx8bWNjWvfB1AF0JEm7v3to+yNqFuYrQVd375HroHTM86vHi5xqONPqOVKsoQ9PxDdM0A03nL87wfdADHdafCaAKjNOC7lbv8eXubTMv58vIys3MFummfXrtBu90hLOWcrE5iivKWECLSlFKiGb1YijKfG9d+5febfxHlEZ99foET0xoDUaVmO8xNTjFyqoLt6nSTA3/MHV8F0FvD1vHusHni1rV7/HF3A92SfHH5E94eH0c5emabbdkPRjEKLXy/j5QST45teZ7XA9Bzlec07KvBs70rYRyll5Y/3Z8en1s3jIkf9cPC30fZ4WKqt5eSIJ+pBMd3i5r34PXXzn4rhFAAAqBer0+s/fbNV3507PHS4sWVmZmZhhAi4z9KqUKn07E8zwuEEDGv0mw2HaVU4ZX5P/4FMa+BkFV7DMUAAAAASUVORK5CYII=",
            },
          ]
        },
        {
          label: "Indoor Mapping",
          items: [
            {
              label: "OpenLevelUp!",
              url: "https://openlevelup.net/?l=0#19/48.84186/2.34487",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACsElEQVQ4jW2TTWhUVxiGn+/cO3NnJtNJmCYG/GmrxZ8KYhdqTJSigqCLlja6KagFA+pGcOWiIGQniEihEMQRxYUSxG5LDREDRqVJq2KMggS1FmOdzF8mmUnHe+45Lsa5xDbf8nDeh/f9Pl4hHCsgFoDdv6YoFFho0mkoZKPCn30VGNJSf+5V0GvoPBNv8pZkRDm7beAbQMk8sSisrwM8L6FXLV98ejSz+bQLCPSaj76+0morzlVxEtutrqKUA9KQ1435vkGpSH7/nq5FSS/66WgGXMB6Xf2fz03pX3Cj6zGlmiAOyHupBQtKiW1OJUsHujtla0ebDN8r+1AHsOHLlUfWrF6y3jG1chAQRdDhZixEooq/X8/QFIs2d6xri/6Vg+LYo7kQ0NoSd3Zubsf6pKyd5xwwBhJxGBnL2VdvZmWiTJDuPx/8dP+cc6EBmHHikg/A6nra/wIqPsw4SXlWnq3t6O+b/GFF8TNSWxLcH60D1o4PTudyH09h/Iq1uI38jQzKESLVwB56edfdv3PRJ2zsEi5lCCP8/PuJJAcOpojFY2g/8oGFwEAyCTcHhbmCx6ZT6IFBe/2pKoYA3dLqupu2eMbzIioI1IeAAJqbsZP/IA9GCAauF88Vv0gN9/wYZ/RyHeAWJmFkCOXFDFqr/y2hKWnlxWOpjI3n+zYc18nD3zgrn+Tc0MHJtUen25+15AKrq1jryjyAtRblKGbfdgYT3x9rWv3drvY2BX9M1SQE/La1J/XtrmWt1Ba+QjwGY0/+ZXoqb76KwY07bx7dHn99NgSk/RlZ6oI1CwMSEZiUChXjquF72VtnM3f28rA7C4gLUKrq2eEHpYL1a9oY48q8CtnA4kaUyZa0evo8O9B/7VUPD7urjQLWf267GGMoEoUJFp48pDugsK/8HhtW/x2FtigfGNY6LgAAAABJRU5ErkJggg==",
              desc: "Données OSM. Malheureusement pas grand chose en Tunisie. "
            },
          ]
        },
        {
          label: "OSM Tools",
          items: [
                {
                  label: "Map Compare | Geofabrik Tools",
                  url: "http://tools.geofabrik.de/mc/#15/49.0094/8.3902&num=4&mt0=mapnik&mt1=geofabrik-basic-colour&mt2=mapnik-german&mt3=here-map",
                  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB00lEQVQ4jZ2Tz2sTURDHPy8GV6uNsSmCFSUthYWlVLqHiJdK8CDxIFoWvPUckRwEbyUhlwWhIef8C54SIvQmUk8qspCQS/Rgtf5IpSTRJjHbkDwPkkeWJlb8wjvMzJvvm+/MPMExyDx/LV2fUPZGNCJG477jCEaTx2Fi1H7xRgJcODPFjHZS+T/sN3B9QlXin0SgDSTzs+dZujjr8dfdQ763O5MrGH15NTwHwMudryr+46CtZGkDyYnR5EIxLz+/2qb/8R2L0xrLus7W+10ArgQDnDul4UpJu9cDoC+EV4LjOMTjcUKhENVqlczmEy7fvq/i1y+FADwSPFNoLayQy+UACM8vYlkWu1tPAfjU/Dm2Tx6C7PqaaC2sYNs2/dYBuq4DsBqeU/1o/eqiDSQb0Yh4fPOa8BeKeek4DqZpcvfOPZFdXxOFYl6OEu80WwBUvu1Tdw89u+Ef6gZIpZPSMAwikYi6EAgEePulpuwjm2gYBgBnp4M8evCQWzeinBZ/htOpN0kkErjbz5TmschkN2W5UpJur+s5jb2abOzVpNvrylQ6KcuV0hEGVc6wF0OYpkksFqNTbwIwNRPEtm0sy2J56erfPwhAuVKSqXTynyv5b5LfXkHxGkq53aIAAAAASUVORK5CYII=",
                  desc: "Permet de comparer 4 cartes synchonisées"
                },
                  {
                    label: "Osmose - Map",
                    url: "http://osmose.openstreetmap.fr/en/map/#zoom=14&lat=36.88734&lon=10.31822&item=xxxx&level=1&tags=&fixable=",
                    icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAABGElEQVQ4jZWSzUrDQBSFv8ncpERoFoVaah/DvSC6cGdB3PgafY6+hhsRBDcuLILvIa5aqQUXFQw28+NiEiwk8eeshrn3zDn3zIV/QgEXJxwcY17RGlXr8GAtssvjPZd3CHB+xHgCCxCIagwHBvYY+IowX7F5YfNELKiahPcUhkQxXwEIoCNiwUur71iIBR1VBKVQDq2RAehau8UsUa4UF8A6SPnIebjmc4OOsI6gbB2dhMN9srS8FKAwkPH2znjSbOn5liyjMNAUyi+QMBNrel1ups2Wel1YE8tWSuTspJyetQ5NvpWS9/gIa7GL5n8Ij4ZDFFIqTDlTI0L1O6VRn2RI4ltXo2NgyKhfEa5mLNUflm/2U3qt+ALAoGnqfPXp+QAAAABJRU5ErkJggg==",
                    desc: "Site qui montre tous les données invalides. Exemple: conflit entre deux tags, adresse sans nom de rue, ..."
                  },
                {
                  label: "OSM Inspector | Geofabrik Tools",
                  url: "http://tools.geofabrik.de/osmi/",
                  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB00lEQVQ4jZ2Tz2sTURDHPy8GV6uNsSmCFSUthYWlVLqHiJdK8CDxIFoWvPUckRwEbyUhlwWhIef8C54SIvQmUk8qspCQS/Rgtf5IpSTRJjHbkDwPkkeWJlb8wjvMzJvvm+/MPMExyDx/LV2fUPZGNCJG477jCEaTx2Fi1H7xRgJcODPFjHZS+T/sN3B9QlXin0SgDSTzs+dZujjr8dfdQ763O5MrGH15NTwHwMudryr+46CtZGkDyYnR5EIxLz+/2qb/8R2L0xrLus7W+10ArgQDnDul4UpJu9cDoC+EV4LjOMTjcUKhENVqlczmEy7fvq/i1y+FADwSPFNoLayQy+UACM8vYlkWu1tPAfjU/Dm2Tx6C7PqaaC2sYNs2/dYBuq4DsBqeU/1o/eqiDSQb0Yh4fPOa8BeKeek4DqZpcvfOPZFdXxOFYl6OEu80WwBUvu1Tdw89u+Ef6gZIpZPSMAwikYi6EAgEePulpuwjm2gYBgBnp4M8evCQWzeinBZ/htOpN0kkErjbz5TmschkN2W5UpJur+s5jb2abOzVpNvrylQ6KcuV0hEGVc6wF0OYpkksFqNTbwIwNRPEtm0sy2J56erfPwhAuVKSqXTynyv5b5LfXkHxGkq53aIAAAAASUVORK5CYII=",
                  desc: "Comme Osmose, sert à la validation des données"
                },
                {
                  label: "GitHub - marook/osm-read", desc: "openstreetmap XML and PBF data parser for node.js and the browser",
                  url: "https://github.com/marook/osm-read",
                  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACJElEQVQ4jY1TMWsUQRT+5r3d3Cbe7t3u3hEDdoJgIVieGo1YBixsBIsUtvkHNpaCnaJFUMEihSlEbGxFE8XCRrDWIAqJyd3t3JGcuduZeRa5DUtAk6968/i+733zhlE4hHq9foWZbwG4pkRmAECU2gDw1lr7Qmu9WuarUs2NOH7Onrdw2LQMa8xyO8tuA7BlA07TdM0juvA/cQHj3KdOp3MZgGUAaMTxssc8nxuzOsrzm90s2yDmU6TUtoh0BKhut9t3Rnl+1/O8Mz7z7GSlcnqwt/cKURS1pptNmW42JUmSpfGQKoCp0tBqkTZJkqWCH0VRiyaYFw/uZ+2zcbkDYFAy2AEgAOCcWymaE8yLBOZZALDOjQB0jrGCdevccH9zPEsYPxWU2uz1eutHqbXWP6DUbwCAyAwV0QDUANAxEhBE6uNaSICfAMBK1dI0vX6UOo7jeSaKsD/5F5FSa04EW+22dca8jMPw0r/ESRheZKKnxVlE3lFuzOPRMIeIWN3v93u7ux+IaBBFUasgBkFwlYi2/SD4yEQnSwaPSGv9xa/4T6abzYm6789Vq9WFMAw/K6W2CmKlUtmJoqhRTmNFHmRZ9vXgLyRx/J6IzlnnzotIX2utS/ywkaabTDQFANaY1+0su7G/0TG6WTYHpVaY6LvH/A3ATMngRCE2xtwrxADA5ViDweDNZBDsQamG7/vLw+HwDwDUajVfiZx1wMNOt3u/rPkLJe7aBdfH1TYAAAAASUVORK5CYII="
                },
          ]
        },
        {
          label: "OSM Data Download",
          items: [
                {
                  label: "Overpass API",
                  url: "https://wiki.openstreetmap.org/wiki/Overpass_API/Language_Guide",
                  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADIElEQVQ4jX3JzW8UZQDA4d8778zszO7sTqe7FloobWn9SCSgNMbIhTapJnrw5BETDz145So3/g5OhniQNBoRjSBJI5JGTFpbSNqYFGS7FNhud3e2uzvf83rxSHyujxjEnXcf99e+Lkp3c0Seum6Xqo/i+Oj9dvT8o53W1oJjOjtvjpxdcUrjd9JoeL4fHXwc078wXXlvXgihBMDDw58eBql/RvkJVnWUNId20GOYDEhbObaapGh6xFnMIO1S9RTzpxfmS+bIug5Qlsd+HsT+GRWlBP0ehWIJnZxOPeP86QXskgGaIohCgqDM3ssmTxuND4B1DcAQzj8FvYisVRBhTpwGxH7EVPUc5YqFJiWWYeOWXPQimEXFztP9ZQAdIBH9Dwt6EUmCsAzqg2fs7g1YPDeFygrkGQShSZLHdMMY3RHkqfZOo9F4Q1NKiUHSupjlEWkc0gs6dOMYW40RhCZBJEhTyTDO6PYVInURQmCWJEdhWNODIDg5jH1PSknmD0nLUEur1LOQMMkx7TZoEb2BR5oaFJwhUthoiZ3VXPeJZtt2K0/EkzAekuUJlrAoGmUse4z7q7cZhAmpCiiWn1Px9nHtArPuLEk7viWlHGpAngfWA4QATZCmGno6iqU7rN68wfr9TZSSgMIydEakx8bWNjWvfB1AF0JEm7v3to+yNqFuYrQVd375HroHTM86vHi5xqONPqOVKsoQ9PxDdM0A03nL87wfdADHdafCaAKjNOC7lbv8eXubTMv58vIys3MFummfXrtBu90hLOWcrE5iivKWECLSlFKiGb1YijKfG9d+5febfxHlEZ99foET0xoDUaVmO8xNTjFyqoLt6nSTA3/MHV8F0FvD1vHusHni1rV7/HF3A92SfHH5E94eH0c5emabbdkPRjEKLXy/j5QST45teZ7XA9Bzlec07KvBs70rYRyll5Y/3Z8en1s3jIkf9cPC30fZ4WKqt5eSIJ+pBMd3i5r34PXXzn4rhFAAAqBer0+s/fbNV3507PHS4sWVmZmZhhAi4z9KqUKn07E8zwuEEDGv0mw2HaVU4ZX5P/4FMa+BkFV7DMUAAAAASUVORK5CYII=",
                  desc: "Overpass est un mirroir de la base de donnée OSM optimisée pour la lecture. Elle offre une API pour télécharger des données spécifiques (restaurants, aéroports, ...) avec une recherche en RegExp sur les tous les tags",
                },
                {
                  label: "overpass turbo",
                  url: "http://overpass-turbo.eu/",
                  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABsElEQVQ4jV3TSWtUURAF4K/7NSEGF2rSiLTzRozSLhQkgrpVEUQEQRD9CVkJ+hvEPyAuspGAEdy4iiImQVcqGpxHxIB0qyhE4pDJxTshjRceXG5VnVN1Tr2K5VNgFbZhPw5hD/7iMUYxgVf4gXmopLiG9TiKU6gnVsFCB8kXDOMmpjBXhHkDzuJMuniKG7ga5o/oxlbsQhc+YLrAGpwIAAzhcke77/EMDzCNJvpzfwsDuI9PuIBNGen/U0vsPN7hHgZqOIiNmE1bi6h26LN0qom9RhvrcKCCO1G7C28whmv4hrkO9l6cVDq0JWAPK/iKnij8O2L9wkzEk9Z7sKIjp46ZpbZauIhB3ApzPe+t3OcSG0xuC4s1TCpt7MaTKL4d55TLBC9T9CJAO6PZZFXpM+xDHz4rLbyUpNncJxLrSy6MVjGe+Zs4gkZYpvAn31TeGslppma8iGCUG9ZUru53rMax2HcbK3EcpwM2hLFC+bO0lDb2Yy92RJfdUb4SsMMpvo4RtIu48FN2W+nx5oDUI25DuQftMI9krPki7S9Y3u1HyiXqxdpo8DysV3A3QPPwD581f83pVbO4AAAAAElFTkSuQmCC",
                  desc: "Interface Visuelle qui utilise l'api Overpass, avec possibilité d'exporter les données téléchargées sous divers formats"
                },
                {
                  label: "BBBike exports",
                  url: "https://download.bbbike.org/",
                  icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACeklEQVQ4jY2RW0jTcRTHP///pptNnZupqKEi3kJLkoIwyvLSxahIK4qiLOpBCCkJ6SZJD2kaUpYaCEJRRtRDEYGFZKU9hHihLLQSyRvqdKRzc3OXXy8biFh6ng6c7/dzDucrsYyqqBXB36xTtWpZVkf4+eVdPiUZPDN5SfNDoemcNba+nBjImXbYdymdJMyf/xdQUiKUvcbpt68nhmKPBEe3JGv1G4UT9bIBE3pLw3PD79TcoMgfwUpN9vCsuaLTPNl4o14ELQm4VGMtfDref3CnPswQp9HWqGTWG+Zs2u/mKVmyofHolP8COFystrqchHr7PuqzmMraTAa1WlY4J+xWPpnGOi/cs3b7alRZi13gBfg0lKfqNvkFupqNQ+dck/3WvtkZLA6nwu4SmOyOABciQm9CLAbIB5qGBzq6NutCq3usJikwKL5dJStI8dP/DFH5sHtleEzlWZ/IggLJ5gEogaNAJaAC7FFRW2+Nzs2uVUoSkoQAsJmNOgTUV+85BHh7nqgF3gPJwDsgSaHwit6WV9P4eKw/bW9gxMj4aK8MIEtKh5AEc5aZcOAjoJOBMqAOKAKaYhIyY/eXDkkv7I60jICQyRWD7ZmfXxVHnw+La/PS+A8i4HRRcxVwBygH6PXEuSOntDi9dMgVda1HHLvaNZK47sBhIAu4D5B3+8+HNdf7xM0aEQ8ogF9Kt9kFkJ518c20sOdmBIRXXcmXEgGTOxULQIJaW5fgozV5mRkAnB7fMyB7QRK+wFcgENC5e/8Fmn3AE4BVQDdwEogBtrifeWae+ATQAmwHktxRfwFCJbdADxQCKcAw8ABoXbBxA3AcCAc6gLvA1F9xsuO6xMCbygAAAABJRU5ErkJggg==",
                  desc: "Téléharger les données OSM incluses dans un polygone dans un grand nombre de formats : Esri Shape Files, GARMIN, PBF, XML ..."
                },
                {
                  label: "Geofabrik Download Server",
                  url: "http://download.geofabrik.de/",
                  desc: "Permet de télécharger les données OSM par pays. C'est de là que ce site est mis à jour."
                },
          ]
        },
      ]
    }
  }
}


/*


    <h3>Satellite Imagery</h3>

        {
          label: "satellites.pro (apple mapkit)",
          url: "https://satellites.pro/Tunisia_map#36.930683,10.336761,10"},
        {
          label: "Google Maps (Maxar ...)",
          url: "https://www.google.com/maps/@36.8956224,10.3217119,17080m/data=!3m1!1e3" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADRUlEQVQ4jS3Sz28UZQAG4Pf9vpmdaa20LPSHbaFsZVurYFWwKOHiSQ5GEo0H4qVIor140cSEiCIQEmIENf4DXiQaTxpvnACDmhg1JpimoRSDKVtLWdrZmdnuzPe9Xnz+hofjX829bkLzifEa2Cw7EDwtLOUFTwEScgIv305x7K91DaUFTFBR1N+Xa8ycZ/3SW2sMbDVtZ74/7mVgLJvtBE4ekNCywOHbKc5fX4EglCQo552MCXZs94ZkNc1TvVp73nxx6E2+M31EPWGMjishEnEhHJ1/gEDAUlHocuO+7uSFIZyK5SbNZtlRf9zL1x49pPd++VK/rS7y4NAUoiBEAY/u0mOw7VBYYmE94+/NhPMbqUhDlW0ZERABAChdyWr0MN7e+xIIwEJKA2KhEqjipanebsxUt+iJ3h7Aldjs3cYgpOVK1sTXN6/y4+eOISlyfPrnd0iLHJGJmAce5TMB3WWPoSjCyGBID8C1c2w89SwD5z3ioIJvF6/jyt0bsDRoZGsIbYTESxfH1nl0X6qlJYHzjq6LCrIcSa3OZP9BGQ/BC+gKK7jfTnAvf4DIRMgccLGeYHZ4FesYxYMXj8P1dCHodNDpfoj3XjiMShzTSILo5b1HaI1oAiVOuDCxodmdDSKeRFp5l8WOA0xnDoBpyub0fpSjuxADMCLkHQhKpQfSUrxQTzRba3Dh3wn9kX6kkdHHNLi1W53pGW1uG1C+52l1WaISxQokkEZyHswccHFyQ8d2Nbi0OqkPr51m2w/g7JFMUyNbWSY1tKb2gn1VRGEoYw3tllemT3kRmYAL9URv1Brm1uqkTl49g9V8CEWZ46eblo8PS/WRbiRb+lFGIaIoYhCGMs4DWSl9tjspj9ca5ubKhE5ePYNGaxCRzVCxFs2cOv29wY27RuP79qD/kRFvjBFJmNRRn9eTfHbXcri4slsfXDuLRmsQXUEG5yxLJ8ah0MyIMz8EnP/Ha3x0u+nbWt303sFeOrFz7mhtrbrQ2F2e+vEcV1oDpjtsy3lrBEokvBcrlmq1aX6+ZTXRX7ipWl9UOC7bb+ZiLJfjT75/5Vy83Bq2XWGGUgEBQvL/RzfyAgMLbLTJX/+25b4x3KkNxCf+Azj/vEDN+7GAAAAAAElFTkSuQmCC"},
        {
          label: "HERE (DigitalGlobe)",
          url: "https://wego.here.com/?map=36.90219,10.31792,14,satellite"},

    <h3>Street View</h3>

        {
          label: "Mapillary - Street-level imagery, powered by collaboration and computer vision",
          url: "https://www.mapillary.com/app/?lat=36.90043414188307&lng=10.307803857674799&z=11.897496531736047"},
        {
          label: "OpenStreetCam",
          url: "https://openstreetcam.org/map/@34.52466147177175,13.447265625000002,5z"},


            <h3>My Maps</h3>

                {
                  label: "uMap",
                  url: "https://umap.openstreetmap.fr/en/"},

            <h3>Mapbox</h3>

                {
                  label: "Mapbox Studio",
                  url: "https://studio.mapbox.com/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVQ4ja2TP2hTURjFf+flJaZkE8TFwcnBKZDqWpNIhpipuCgElL4MTjW4OXXRtYqIQgIOgU7dlA6hprg3UHAQnBz9M5fXNjfvc3hX8/rEguCZ7r3fOef7c+8VOTR7R1fMCneBmqAGYDAFptJ86/2g/DnL12JpakSzddBTYClv7BGDPZ4Mi89BljEwNSL3Dmj/RZjHzmQYdkAWAKSZc2LTF5nuBSRXMVZAL4DER9teg3zPB7myP4XH4bXxSIetrlXGIx0C1CPXFvYWCIBYmlcDP7BTPZt48Evkyq5fX3O3APaG4Q7opactmRXuBPhJZ7C9Nyh+uLFhYSM6uY/xSNhms+c6qbttZ7jLgXIGMtsFKh3lvFAVqCAuGFZtda0SKPnxmwu1MD/eROwDjF/rO7DejGYYdjAZlN4ANNfcdX+DAAT+kSz6p/gtuy+E4ZPkUnEE0OpaxWBjwWUakhp0Fg7H5ayBr4R6b7bizL0CLmfC+39eo7SaJO4jOnckZhcDY9mkm8DtXLexNK8KoBGdPARt5udxNqw/GZae/Z+nDLL0wPpAfIYwTjOnYjj1G1P863f+CZM1uINdo556AAAAAElFTkSuQmCC"},
                {
                  label: "Style Specification",
                  url: "https://docs.mapbox.com/mapbox-gl-js/style-spec/"},
                {
                  label: "Examples (js)",
                  url: "https://docs.mapbox.com/mapbox-gl-js/examples/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVQ4ja2TP2hTURjFf+flJaZkE8TFwcnBKZDqWpNIhpipuCgElL4MTjW4OXXRtYqIQgIOgU7dlA6hprg3UHAQnBz9M5fXNjfvc3hX8/rEguCZ7r3fOef7c+8VOTR7R1fMCneBmqAGYDAFptJ86/2g/DnL12JpakSzddBTYClv7BGDPZ4Mi89BljEwNSL3Dmj/RZjHzmQYdkAWAKSZc2LTF5nuBSRXMVZAL4DER9teg3zPB7myP4XH4bXxSIetrlXGIx0C1CPXFvYWCIBYmlcDP7BTPZt48Evkyq5fX3O3APaG4Q7opactmRXuBPhJZ7C9Nyh+uLFhYSM6uY/xSNhms+c6qbttZ7jLgXIGMtsFKh3lvFAVqCAuGFZtda0SKPnxmwu1MD/eROwDjF/rO7DejGYYdjAZlN4ANNfcdX+DAAT+kSz6p/gtuy+E4ZPkUnEE0OpaxWBjwWUakhp0Fg7H5ayBr4R6b7bizL0CLmfC+39eo7SaJO4jOnckZhcDY9mkm8DtXLexNK8KoBGdPARt5udxNqw/GZae/Z+nDLL0wPpAfIYwTjOnYjj1G1P863f+CZM1uINdo556AAAAAElFTkSuQmCC"},
                {
                  label: "API Reference",
                  url: "https://docs.mapbox.com/mapbox-gl-js/api/"},
                {
                  label: "VueMapbox",
                  url: "https://soal.github.io/vue-mapbox/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAC0klEQVQ4jW3SXWiWZRzH8e//uu7nvvfiPafbfFIb4UtbEEX5crKWTUWbGx4mdBCJIEKQhAcltuIRpgcqaHRUQ4sOQiSIXsWaThmeDCU9sRpZPsMX2Nx8mW577vu+rn8H9jxI7jr6H/z+H/5w/YTHn6qsO7X/ZY9uF/R1hGZAUG4J0u89n3UMZUOFQsGXV6Q8vHHihB2L/3hLRD5GWEKm6IwDBamykBNQrgu6715j/ujFVTtSgKAMjMfDW0TkCF7nZn8/JLtyh38neQTU5Qiei8m1xE9rTg7OvT1aAr6oXLDuh97FmtOfUV5MLkyQDE04SfwFD+cMkinaRmDaci/Vh1FbA1iuWOs29W/YOxIA+JB2gefdjWnSi3dUM/okk545PUtL4fxaffjO7yaty3anl+++bxdVBcGzcatztANfGwBRbQGsG5mCaXfVOH9g6PRX45LY7cmtma3nzx+bdEF6mMRfcsUp8GrBLAf4DxAF0JJHlRtRR/2Dtb/07kLZLfDh2pO9O+PO1hmgqMmjD1BRVwFU/AgiKnGAiixIh9NagX9AJ4BxrF6bvHyzCmGhqcuB4PFarABG7SVUx+ziakyNXeZuPnjlzMaeb1XZC1IY2PjR93ZkeqVE9gXbXA3IqAi/VYBQ4z+Bc3ZBhF1SGyKya+Wmt5fR6b/RKPtuddfWp7zygX2mJrYLq0F14H5jfrgCnOzaWTL4PqzcDVfNwzREK8TrJ5Obry66d6A4D+8O2fqwI1w9h3Iyocrn5SKZcpFcqAOoHjMNIdFrTZi6oNukHA80Oy41wZvhq41i8pGK0tc02Tr4RJUB1vfvy/vMf4nQ6a5NUTo7ijolWtNEsHwOAj+m3m4b7NozNisAsP6n/S3euqMI7f52Ak4x+QhVBsFtO9tZ+Ovx/BNABTHuUwwbAPD8arx993T3nuH/Z2cFANac6m226g8LYnHy3pnunuJsuX8BjQI3MyUm4k0AAAAASUVORK5CYII="},

            <h3>OSM</h3>

                {
                  label: "Wiki",
                  url: "https://wiki.openstreetmap.org/wiki/Main_Page" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADIElEQVQ4jX3JzW8UZQDA4d8778zszO7sTqe7FloobWn9SCSgNMbIhTapJnrw5BETDz145So3/g5OhniQNBoRjSBJI5JGTFpbSNqYFGS7FNhud3e2uzvf83rxSHyujxjEnXcf99e+Lkp3c0Seum6Xqo/i+Oj9dvT8o53W1oJjOjtvjpxdcUrjd9JoeL4fHXwc078wXXlvXgihBMDDw58eBql/RvkJVnWUNId20GOYDEhbObaapGh6xFnMIO1S9RTzpxfmS+bIug5Qlsd+HsT+GRWlBP0ehWIJnZxOPeP86QXskgGaIohCgqDM3ssmTxuND4B1DcAQzj8FvYisVRBhTpwGxH7EVPUc5YqFJiWWYeOWXPQimEXFztP9ZQAdIBH9Dwt6EUmCsAzqg2fs7g1YPDeFygrkGQShSZLHdMMY3RHkqfZOo9F4Q1NKiUHSupjlEWkc0gs6dOMYW40RhCZBJEhTyTDO6PYVInURQmCWJEdhWNODIDg5jH1PSknmD0nLUEur1LOQMMkx7TZoEb2BR5oaFJwhUthoiZ3VXPeJZtt2K0/EkzAekuUJlrAoGmUse4z7q7cZhAmpCiiWn1Px9nHtArPuLEk7viWlHGpAngfWA4QATZCmGno6iqU7rN68wfr9TZSSgMIydEakx8bWNjWvfB1AF0JEm7v3to+yNqFuYrQVd375HroHTM86vHi5xqONPqOVKsoQ9PxDdM0A03nL87wfdADHdafCaAKjNOC7lbv8eXubTMv58vIys3MFummfXrtBu90hLOWcrE5iivKWECLSlFKiGb1YijKfG9d+5febfxHlEZ99foET0xoDUaVmO8xNTjFyqoLt6nSTA3/MHV8F0FvD1vHusHni1rV7/HF3A92SfHH5E94eH0c5emabbdkPRjEKLXy/j5QST45teZ7XA9Bzlec07KvBs70rYRyll5Y/3Z8en1s3jIkf9cPC30fZ4WKqt5eSIJ+pBMd3i5r34PXXzn4rhFAAAqBer0+s/fbNV3507PHS4sWVmZmZhhAi4z9KqUKn07E8zwuEEDGv0mw2HaVU4ZX5P/4FMa+BkFV7DMUAAAAASUVORK5CYII="},
                {
                  label: "Tag Info",
                  url: "https://taginfo.openstreetmap.org/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAB30lEQVQ4jW1SvaryQBDd3cTYpZCAiBdBQQvBnwewsrFK4wOIaXwBwdpKC5/B0sYnsBWilQhRURDUwsYuggiJ2Z25xdwvWHxTnTkMZ2bODAcA9i8454iIiIRfr9d0OvV933GcfD6vlBJCMAAAAKVUGIZhGEopASCKIqWU4zgkVKlUfN+nMqaUAoDT6VSr1crl8nK5BAApZRiGhUKBMSaEYIx5noeIUkpBA7zfb8/zjsfj8/mUUkopE4lEt9tljAGAbdulUgkAhBAsiiJEvF6vhmFwzlerFa2hlELE8/m83W5jBgC04XAYBMF4PF6v19T0cDik02nLsmazmeu6l8tlv9/X63VN0xhjDBEfj0fsEoH5fI6IjUaD0p+fnzAMqYlARNM0+/2+EAIRO53OaDSqVquImEqldF3Xdd2yLHKcc86klLRDIpFgjLmui4gAgIi2bce2UgcAEDSV7/tkl+/7URR9Ph/iv29KQHDOOee6rhMlhIhxXPQdIv4FCjKX5L/5GAtSUkpRfr/fifmv/N9IiJjJZAzDYIwNBoNms9nr9RBR0zT+FX9N6NUQcTKZJJNJksnlckqpVqtFabFYDIKA7sDJQVp3s9nsdjtEzGazrVZrsVjcbjdd103TbLfb5PsvYkBdl2UzHk4AAAAASUVORK5CYII="},
                {
                  label: "Contributors&#39; stats",
                  url: "http://hdyc.neis-one.org/?selimachour" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA1klEQVQ4jdXQPUqDYRAE4OdLosGQIDaprCKIxMLGA2hpL2LpBVJ5AfEKHsDCAwii1qlzAytJEStjYxAR/5oVlo9EC6tM8w4zw+7sy9yjSLwS71fon1OyxS/+/7CKUxzjFnuhV1ELvo5LXGMrtQLdqHaBGwxLCxZxhz6u8ICVHNjAJMTtGNDFOc5wiDFakX/EvlSvwAcWsIzXCO/gObZPQhe8kRts4h1t7OIJzeS38YIT9PCGTh6whkE6oZ82/HzUAe4xwlHJUz6najrqWJrh/YnKDD7v+Abk+CXe5XHu5AAAAABJRU5ErkJggg=="},
                {
                  label: "Changesets",
                  url: "https://www.openstreetmap.org/changeset/67138409" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADZElEQVQ4jWXSS2yUVRjG8eec881815nOdGbSwlgt0yYtGCQikjAQgxtWRmPQboxuZOGm6sr1bFwY48YEFyyIy3agiTeQgAGtsUQjLWmCSmOhLZSx7dw6l+9+zuvChBj9rf6rZ/Ww253qiTjAWSJ1G0E8YyRzm6ZlHWt53dMb7bVywogXdNeY7bb1+eFC/nnHZq/Yll1aurJzfGpqKmQLD6pmwqBbmbSzv7HRkFY+h3IuzGbQQ6OzjaQ0YMUjGDCHehHFTsevw7EClEaz5SF2/CYvj0x5YRTNRTKOpReQ122ZUegpGboyaBq0Vy/LfcVJlSuknXTaVJnUsN91MzQ/v/YCAHAAgKfVkpqj6ZkBYq5SEDHz6p7IG/tQyNuCcckYCTKSBoPuJ4RFkDLzWoWIawAwmM2WDWHBcBQY+Wy9dZ+t7kiUS6MsjkzImDNOGtw4RCfYYrFwoacSk2vvfDCm/dqsDkgWn+y4bUgvELtekzWkj7w9Dj900Hc5FAFBJNh3BcAHGFlNKI10XceYprruE55OOS4F0AuYSjLakxxmjzba6Oo+rIwH8B76bg6KkqQbXXLMLLdt4e5uN5b5xx99vt533TWoGKEMlJlwmMmGITGIn3+8gZgJEHdhOTXYmU3kUgZNDB6g1ubuhZmZ83V+4bPve+16Z1G3dJCKVOgLMngWKcPBd1/M4kr1G8ShA6ZpSJs22aqAP/5cwWpt8TqAWAMAKeXvUhH8RBqOJLo6M8ta9+5jdFxD11vBb8sWbN2EYZssklImk4yPPOlMAlAaADxVPLDXEClkC13Mnb/Mrs4sUUIPcea9t/D0s0U0ezX06x3Udlrw7JCPpiextRo+BABtenpa97XoVFL1aPbsJf5t9SYJjeill4+y8f1F7qm8HMoCWo6xu517qqk6aMQP/WuXb1wHAO3gq8VxP+6PzX54CdfmfmLZgsXfeP8UjkyU0BNtlc0nhWB7ELMtRFHMc2YGzc3dR1LfrQGAljL1/vrSyrnW6srbigXi0IlDNdNOLF786ocvf1lYXj395sljuULpxVRGL9X+2l7zw/DOgzvbF78+d8vFvxjvnil/euTowU8mDh9+Bv/HU6lU7vH1/4NVq68LoJjLlp4bAIAqVQVRhaMC/k8TB4AKVThRVVQqlcdDfwOK6qV7BRBy3AAAAABJRU5ErkJggg=="},
                {
                  label: "Node: 2218422994 | OpenStreetMap",
                  url: "https://www.openstreetmap.org/node/2218422994" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADZElEQVQ4jWXSS2yUVRjG8eec881815nOdGbSwlgt0yYtGCQikjAQgxtWRmPQboxuZOGm6sr1bFwY48YEFyyIy3agiTeQgAGtsUQjLWmCSmOhLZSx7dw6l+9+zuvChBj9rf6rZ/Ww253qiTjAWSJ1G0E8YyRzm6ZlHWt53dMb7bVywogXdNeY7bb1+eFC/nnHZq/Yll1aurJzfGpqKmQLD6pmwqBbmbSzv7HRkFY+h3IuzGbQQ6OzjaQ0YMUjGDCHehHFTsevw7EClEaz5SF2/CYvj0x5YRTNRTKOpReQ122ZUegpGboyaBq0Vy/LfcVJlSuknXTaVJnUsN91MzQ/v/YCAHAAgKfVkpqj6ZkBYq5SEDHz6p7IG/tQyNuCcckYCTKSBoPuJ4RFkDLzWoWIawAwmM2WDWHBcBQY+Wy9dZ+t7kiUS6MsjkzImDNOGtw4RCfYYrFwoacSk2vvfDCm/dqsDkgWn+y4bUgvELtekzWkj7w9Dj900Hc5FAFBJNh3BcAHGFlNKI10XceYprruE55OOS4F0AuYSjLakxxmjzba6Oo+rIwH8B76bg6KkqQbXXLMLLdt4e5uN5b5xx99vt533TWoGKEMlJlwmMmGITGIn3+8gZgJEHdhOTXYmU3kUgZNDB6g1ubuhZmZ83V+4bPve+16Z1G3dJCKVOgLMngWKcPBd1/M4kr1G8ShA6ZpSJs22aqAP/5cwWpt8TqAWAMAKeXvUhH8RBqOJLo6M8ta9+5jdFxD11vBb8sWbN2EYZssklImk4yPPOlMAlAaADxVPLDXEClkC13Mnb/Mrs4sUUIPcea9t/D0s0U0ezX06x3Udlrw7JCPpiextRo+BABtenpa97XoVFL1aPbsJf5t9SYJjeill4+y8f1F7qm8HMoCWo6xu517qqk6aMQP/WuXb1wHAO3gq8VxP+6PzX54CdfmfmLZgsXfeP8UjkyU0BNtlc0nhWB7ELMtRFHMc2YGzc3dR1LfrQGAljL1/vrSyrnW6srbigXi0IlDNdNOLF786ocvf1lYXj395sljuULpxVRGL9X+2l7zw/DOgzvbF78+d8vFvxjvnil/euTowU8mDh9+Bv/HU6lU7vH1/4NVq68LoJjLlp4bAIAqVQVRhaMC/k8TB4AKVThRVVQqlcdDfwOK6qV7BRBy3AAAAABJRU5ErkJggg=="},

            <h3>OSM Tools</h3>

                {
                  label: "overpass turbo",
                  url: "http://overpass-turbo.eu/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABsElEQVQ4jV3TSWtUURAF4K/7NSEGF2rSiLTzRozSLhQkgrpVEUQEQRD9CVkJ+hvEPyAuspGAEdy4iiImQVcqGpxHxIB0qyhE4pDJxTshjRceXG5VnVN1Tr2K5VNgFbZhPw5hD/7iMUYxgVf4gXmopLiG9TiKU6gnVsFCB8kXDOMmpjBXhHkDzuJMuniKG7ga5o/oxlbsQhc+YLrAGpwIAAzhcke77/EMDzCNJvpzfwsDuI9PuIBNGen/U0vsPN7hHgZqOIiNmE1bi6h26LN0qom9RhvrcKCCO1G7C28whmv4hrkO9l6cVDq0JWAPK/iKnij8O2L9wkzEk9Z7sKIjp46ZpbZauIhB3ApzPe+t3OcSG0xuC4s1TCpt7MaTKL4d55TLBC9T9CJAO6PZZFXpM+xDHz4rLbyUpNncJxLrSy6MVjGe+Zs4gkZYpvAn31TeGslppma8iGCUG9ZUru53rMax2HcbK3EcpwM2hLFC+bO0lDb2Yy92RJfdUb4SsMMpvo4RtIu48FN2W+nx5oDUI25DuQftMI9krPki7S9Y3u1HyiXqxdpo8DysV3A3QPPwD581f83pVbO4AAAAAElFTkSuQmCC"},
                {
                  label: "Overpass API Doc",
                  url: "https://wiki.openstreetmap.org/wiki/Overpass_API/Language_Guide" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADIElEQVQ4jX3JzW8UZQDA4d8778zszO7sTqe7FloobWn9SCSgNMbIhTapJnrw5BETDz145So3/g5OhniQNBoRjSBJI5JGTFpbSNqYFGS7FNhud3e2uzvf83rxSHyujxjEnXcf99e+Lkp3c0Seum6Xqo/i+Oj9dvT8o53W1oJjOjtvjpxdcUrjd9JoeL4fHXwc078wXXlvXgihBMDDw58eBql/RvkJVnWUNId20GOYDEhbObaapGh6xFnMIO1S9RTzpxfmS+bIug5Qlsd+HsT+GRWlBP0ehWIJnZxOPeP86QXskgGaIohCgqDM3ssmTxuND4B1DcAQzj8FvYisVRBhTpwGxH7EVPUc5YqFJiWWYeOWXPQimEXFztP9ZQAdIBH9Dwt6EUmCsAzqg2fs7g1YPDeFygrkGQShSZLHdMMY3RHkqfZOo9F4Q1NKiUHSupjlEWkc0gs6dOMYW40RhCZBJEhTyTDO6PYVInURQmCWJEdhWNODIDg5jH1PSknmD0nLUEur1LOQMMkx7TZoEb2BR5oaFJwhUthoiZ3VXPeJZtt2K0/EkzAekuUJlrAoGmUse4z7q7cZhAmpCiiWn1Px9nHtArPuLEk7viWlHGpAngfWA4QATZCmGno6iqU7rN68wfr9TZSSgMIydEakx8bWNjWvfB1AF0JEm7v3to+yNqFuYrQVd375HroHTM86vHi5xqONPqOVKsoQ9PxDdM0A03nL87wfdADHdafCaAKjNOC7lbv8eXubTMv58vIys3MFummfXrtBu90hLOWcrE5iivKWECLSlFKiGb1YijKfG9d+5febfxHlEZ99foET0xoDUaVmO8xNTjFyqoLt6nSTA3/MHV8F0FvD1vHusHni1rV7/HF3A92SfHH5E94eH0c5emabbdkPRjEKLXy/j5QST45teZ7XA9Bzlec07KvBs70rYRyll5Y/3Z8en1s3jIkf9cPC30fZ4WKqt5eSIJ+pBMd3i5r34PXXzn4rhFAAAqBer0+s/fbNV3507PHS4sWVmZmZhhAi4z9KqUKn07E8zwuEEDGv0mw2HaVU4ZX5P/4FMa+BkFV7DMUAAAAASUVORK5CYII="},
                {
                  label: "BBBike exports",
                  url: "https://download.bbbike.org/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACeklEQVQ4jY2RW0jTcRTHP///pptNnZupqKEi3kJLkoIwyvLSxahIK4qiLOpBCCkJ6SZJD2kaUpYaCEJRRtRDEYGFZKU9hHihLLQSyRvqdKRzc3OXXy8biFh6ng6c7/dzDucrsYyqqBXB36xTtWpZVkf4+eVdPiUZPDN5SfNDoemcNba+nBjImXbYdymdJMyf/xdQUiKUvcbpt68nhmKPBEe3JGv1G4UT9bIBE3pLw3PD79TcoMgfwUpN9vCsuaLTPNl4o14ELQm4VGMtfDref3CnPswQp9HWqGTWG+Zs2u/mKVmyofHolP8COFystrqchHr7PuqzmMraTAa1WlY4J+xWPpnGOi/cs3b7alRZi13gBfg0lKfqNvkFupqNQ+dck/3WvtkZLA6nwu4SmOyOABciQm9CLAbIB5qGBzq6NutCq3usJikwKL5dJStI8dP/DFH5sHtleEzlWZ/IggLJ5gEogaNAJaAC7FFRW2+Nzs2uVUoSkoQAsJmNOgTUV+85BHh7nqgF3gPJwDsgSaHwit6WV9P4eKw/bW9gxMj4aK8MIEtKh5AEc5aZcOAjoJOBMqAOKAKaYhIyY/eXDkkv7I60jICQyRWD7ZmfXxVHnw+La/PS+A8i4HRRcxVwBygH6PXEuSOntDi9dMgVda1HHLvaNZK47sBhIAu4D5B3+8+HNdf7xM0aEQ8ogF9Kt9kFkJ518c20sOdmBIRXXcmXEgGTOxULQIJaW5fgozV5mRkAnB7fMyB7QRK+wFcgENC5e/8Fmn3AE4BVQDdwEogBtrifeWae+ATQAmwHktxRfwFCJbdADxQCKcAw8ABoXbBxA3AcCAc6gLvA1F9xsuO6xMCbygAAAABJRU5ErkJggg=="},
                {
                  label: "Geofabrik Download Server",
                  url: "http://download.geofabrik.de/"},
                {
                  label: "Map Compare | Geofabrik Tools",
                  url: "http://tools.geofabrik.de/mc/#15/49.0094/8.3902&num=4&mt0=mapnik&mt1=geofabrik-basic-colour&mt2=mapnik-german&mt3=here-map" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB00lEQVQ4jZ2Tz2sTURDHPy8GV6uNsSmCFSUthYWlVLqHiJdK8CDxIFoWvPUckRwEbyUhlwWhIef8C54SIvQmUk8qspCQS/Rgtf5IpSTRJjHbkDwPkkeWJlb8wjvMzJvvm+/MPMExyDx/LV2fUPZGNCJG477jCEaTx2Fi1H7xRgJcODPFjHZS+T/sN3B9QlXin0SgDSTzs+dZujjr8dfdQ763O5MrGH15NTwHwMudryr+46CtZGkDyYnR5EIxLz+/2qb/8R2L0xrLus7W+10ArgQDnDul4UpJu9cDoC+EV4LjOMTjcUKhENVqlczmEy7fvq/i1y+FADwSPFNoLayQy+UACM8vYlkWu1tPAfjU/Dm2Tx6C7PqaaC2sYNs2/dYBuq4DsBqeU/1o/eqiDSQb0Yh4fPOa8BeKeek4DqZpcvfOPZFdXxOFYl6OEu80WwBUvu1Tdw89u+Ef6gZIpZPSMAwikYi6EAgEePulpuwjm2gYBgBnp4M8evCQWzeinBZ/htOpN0kkErjbz5TmschkN2W5UpJur+s5jb2abOzVpNvrylQ6KcuV0hEGVc6wF0OYpkksFqNTbwIwNRPEtm0sy2J56erfPwhAuVKSqXTynyv5b5LfXkHxGkq53aIAAAAASUVORK5CYII="},
                {
                  label: "OSM Inspector | Geofabrik Tools",
                  url: "http://tools.geofabrik.de/osmi/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAB00lEQVQ4jZ2Tz2sTURDHPy8GV6uNsSmCFSUthYWlVLqHiJdK8CDxIFoWvPUckRwEbyUhlwWhIef8C54SIvQmUk8qspCQS/Rgtf5IpSTRJjHbkDwPkkeWJlb8wjvMzJvvm+/MPMExyDx/LV2fUPZGNCJG477jCEaTx2Fi1H7xRgJcODPFjHZS+T/sN3B9QlXin0SgDSTzs+dZujjr8dfdQ763O5MrGH15NTwHwMudryr+46CtZGkDyYnR5EIxLz+/2qb/8R2L0xrLus7W+10ArgQDnDul4UpJu9cDoC+EV4LjOMTjcUKhENVqlczmEy7fvq/i1y+FADwSPFNoLayQy+UACM8vYlkWu1tPAfjU/Dm2Tx6C7PqaaC2sYNs2/dYBuq4DsBqeU/1o/eqiDSQb0Yh4fPOa8BeKeek4DqZpcvfOPZFdXxOFYl6OEu80WwBUvu1Tdw89u+Ef6gZIpZPSMAwikYi6EAgEePulpuwjm2gYBgBnp4M8evCQWzeinBZ/htOpN0kkErjbz5TmschkN2W5UpJur+s5jb2abOzVpNvrylQ6KcuV0hEGVc6wF0OYpkksFqNTbwIwNRPEtm0sy2J56erfPwhAuVKSqXTynyv5b5LfXkHxGkq53aIAAAAASUVORK5CYII="},
                {
                  label: "Osmose - Map",
                  url: "http://osmose.openstreetmap.fr/en/map/#zoom=14&lat=36.88734&lon=10.31822&item=xxxx&level=1&tags=&fixable=" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAABGElEQVQ4jZWSzUrDQBSFv8ncpERoFoVaah/DvSC6cGdB3PgafY6+hhsRBDcuLILvIa5aqQUXFQw28+NiEiwk8eeshrn3zDn3zIV/QgEXJxwcY17RGlXr8GAtssvjPZd3CHB+xHgCCxCIagwHBvYY+IowX7F5YfNELKiahPcUhkQxXwEIoCNiwUur71iIBR1VBKVQDq2RAehau8UsUa4UF8A6SPnIebjmc4OOsI6gbB2dhMN9srS8FKAwkPH2znjSbOn5liyjMNAUyi+QMBNrel1ups2Wel1YE8tWSuTspJyetQ5NvpWS9/gIa7GL5n8Ij4ZDFFIqTDlTI0L1O6VRn2RI4ltXo2NgyKhfEa5mLNUflm/2U3qt+ALAoGnqfPXp+QAAAABJRU5ErkJggg=="},
                {
                  label: "GitHub - marook/osm-read: an openstreetmap XML and PBF data parser for node.js and the browser",
                  url: "https://github.com/marook/osm-read" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACJElEQVQ4jY1TMWsUQRT+5r3d3Cbe7t3u3hEDdoJgIVieGo1YBixsBIsUtvkHNpaCnaJFUMEihSlEbGxFE8XCRrDWIAqJyd3t3JGcuduZeRa5DUtAk6968/i+733zhlE4hHq9foWZbwG4pkRmAECU2gDw1lr7Qmu9WuarUs2NOH7Onrdw2LQMa8xyO8tuA7BlA07TdM0juvA/cQHj3KdOp3MZgGUAaMTxssc8nxuzOsrzm90s2yDmU6TUtoh0BKhut9t3Rnl+1/O8Mz7z7GSlcnqwt/cKURS1pptNmW42JUmSpfGQKoCp0tBqkTZJkqWCH0VRiyaYFw/uZ+2zcbkDYFAy2AEgAOCcWymaE8yLBOZZALDOjQB0jrGCdevccH9zPEsYPxWU2uz1eutHqbXWP6DUbwCAyAwV0QDUANAxEhBE6uNaSICfAMBK1dI0vX6UOo7jeSaKsD/5F5FSa04EW+22dca8jMPw0r/ESRheZKKnxVlE3lFuzOPRMIeIWN3v93u7ux+IaBBFUasgBkFwlYi2/SD4yEQnSwaPSGv9xa/4T6abzYm6789Vq9WFMAw/K6W2CmKlUtmJoqhRTmNFHmRZ9vXgLyRx/J6IzlnnzotIX2utS/ywkaabTDQFANaY1+0su7G/0TG6WTYHpVaY6LvH/A3ATMngRCE2xtwrxADA5ViDweDNZBDsQamG7/vLw+HwDwDUajVfiZx1wMNOt3u/rPkLJe7aBdfH1TYAAAAASUVORK5CYII="},

            <h3>Data</h3>

                {
                  label: "GADM - administrative areas of all countries",
                  url: "https://gadm.org/download_country_v3.html" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADHElEQVQ4jaWTW0gUcRTG/zM7OzPOXtJ1d8ttLcJN0dTUyiLKlJWgK4IVkWYmJrZqiNkF6WHwIUpIRAQ3lcVMykwishS8laWVJmVamprmdTVvu81eZ3Z2p5cMUuul7/HjnN858J0DwH8KWs18X+xz8t1376ZG9fAJJ99BrNGmFvWHB/p2aI71LK+FlxsZGRniAMnY/Z1eY2G03BDjIuyKupgzl/s2Rz1ZbdgKgNPpdHEAAtOUxwyAXCrYJug2iSXJqMNWMqTzSh7WKar/CYhVtQfgiAPgCCMBPJfS8CXNyvJQ6ZWBa3VKwXwBgFwvSJJE/grw95zQAADANB+VAwdvsleW4EvQpqoEz8dJFEM0qPH6vlu702fCSuuD/wCQJIkMlCqvygWGMyYGN9T7mGGI43XRCL4/euRRDY4wJwsNSTf0Xr41hN18L3iqYxCAXyl0azfFK0VzWR6YOdRgF/V2mDbm3I4czwWjoZWt8EPNR32QDoZYYfDazxsA4NgDlkP5dp+xTFlxbDqckpJCKAQLRTSLdr2eDow6pxIVaNUjWmhBXtMKVUcTjKlayLeeum67+JrGiMgoR1wFtaPnpRNwuEKhsEO/1kd71LoIVrqYx/GdYrb/YHYbrs3CGLqrfTpcKEatEj/ZgNnNQQ2G+29N4htFupx3glEWwCMwAAD0qHWJ9EZ9I8Qi3YOddelNogqt0Pyj8sNciNgds0gjsKYmJx8N3L1uL4JQREthPxvmL5nSIRwQIwAAwFvwbBNYsX1vTLUqvXLTUw9qJrvTuEshxGyqbby2PL3MR7eH0Vxg3anrN9vF9+TCufihxfXR29O+tv2O7ziZL+G32hhJnT53oHR9gqUKXcwpyQxBn1vm/e6+OXpkyG3w9DO/VOsDlBovl2avuIMtgDL6fes8XNV7SLtBPFs0a3PPKfa+lIw7rG+DlHEuDuLklydsZghwoHk0sGwFgCRJ16fEfQ3DNhk7QcnK74xpymhUEC80L1Y6pIZdMI21MBxmHKXWVpwlXxiX+lb9xiUFlTVHbJt81WkJqJXjC572u+cbZpfX/AQGQlYo/NMp5AAAAABJRU5ErkJggg=="},

            <h3>Tunisie</h3>

                {
                  label: "Kcit.org - Carto Citoyenne",
                  url: "http://kcit.org/site0/index0.html?#art_PR-Accueil" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAACfUlEQVQ4jU2PTUtUcRyFz+//MnPnTmozwnUEHTRoVKKFmO7btGsXEa6iVTtp1zbBL+BCEHdB3yAEoQRNExQRSntBogbFEdNRG2fuzP2//FpMRc/6cM5zyHsPIrZWKAUg/vatWakkR0dgTvX2pguFsFQC4K0lpcBMzOytFUo1y+Wjly+PFxYaBwcSAOCAIIoKT570Pn6cHRpqx8gZI5S6WF398vTp1efPmZ4eymTIewBMxEkSVyrhwMDw/Hz+3j1vDHnm+u7uh/v3k0olKBbtxYWr1wEwQIAIAp3PJ8fHFAS3X7/umpgQ3GodzM7G5XLQ32/OziBE39TU2NbW+M5O8flzEYbm50/d02Or1fLMjG+1cLmz81bK9WJxtbv7XaFwubnJ/1H79Gl9cHA1l3s/OPgGOF9ZEbXtbQBE5BuNGzMznePjPknAzMzemGsjI6W5OR/H7JxIpS42NkR9b0+m02wtCxE9esTOkdYMACCl2Lnc3bvQmo2RQdDY3RVsLYiYGUQkJUnZHkQbKUkIEYZgBpFrNkXQ389JQlr7er22tfXPngBmhrWtw0NXq0FKZhZSqo6xMWuMqtczN2/qKGrXExEDgghC7D97RkQkBDFDSnFtdPT6xERyenrr1atsqQQiAC6OOUmah4cfHz6sLi2pri4wt1+pVC7XNzXFSdJ5546LYwAfHjy42txMRVHzxw8Q6Xwefz2hlGLmwuQkAG+MSKe/v3hRXVzM9PWZkxOdy5GU7ahQSmhNWisA3jl4L7Surq19nZ7OBoG9umLvXaMB75kZzoHIGGOrVfqzBYDZnJ9fbm//Wl5u7O9TKpWKIpnNUjqtOjtlR4cIw3B4+Df+mWo83ZpMEAAAAABJRU5ErkJggg=="},
                {
                  label: "Les itinéraires des lignes du réseau bus de la TRANSTU - uMap",
                  url: "http://umap.openstreetmap.fr/en/map/les-itineraires-des-lignes-du-reseau-bus-de-la-tra_359049#20/36.88034/10.32694" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACLUlEQVQ4jYWST0iTcRjHP8+7rc2DBkmRoEYUg0YQm5iI7VW8JFGXZFPq4qVTZHbpkBdP4cFL1zrYpWQJHewPdJGXUsRw2R+aICak0T9ZkFZz7t2eDu+7OfxTv9PD98/zfJ+HH2x/sgO2K+ctFqFQbI+/mqN7PSvzlmXZ4fbzh8T2XQJQb+7O7PjDj21tbZ6f+f3BbJqFVGp0A8Bw7ANGRbUMZdMsWJZlh824KbZ3EbQftF9s72LYjJuWZdnZNAsV1TIEA0apQaR1blDR5VJX4cpmcwAMFyOVGt1QdDnSOjcIYDSa3XWo9uHh3aZej21fvxyTt6j2NZrddUaeQi/gM/KyVia4vcP9SpgBvwBfnkKvF2gBKIjWFgUBe3044wkcBnpc6G7AXh8u8o5WAFq8QNCdEAUSAJOTY2vAtYaGc/0AyeSjP1vSRN0iKBEz/hmoAdZ8WV/t9PS91e3xN19T08WqnD/3CagEvhiIJl2u0g7kev7hBcDVVDpBNGmgkiyxymX+9xMdjauXGSNfkPtAxvETDEdjnbu5w9FYp5ZuRiZfkBHPt6X36Zr6478ROgBEpONgfWjs61JqZYs5JCJjgN+dfv31ROJJMa6EzfhjgTPuKh/8tp6cmhr9AdDcHNuX9cpLhCNu0qezzx+cBbR8X4lEu64iehOoAMarjO+nAVYLB54B7UAGlRuvXiRuOX12ONiJU11Bj6EXEG1QlWlnLW1CZSZfkJE3E4n5cv1fzXLMmD/t63oAAAAASUVORK5CYII="},
                {
                  label: "(5) OpenStreetMap Tunisia",
                  url: "https://www.facebook.com/groups/47277958581/?ref=bookmarks" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArUlEQVQ4jWN0yti8leH/fy8GcgAj4zYmsjUzMDAw/P/vxUS2ZihgIUYRDycrQ1aYNoOlnjgDLxcbw9yNNxiW77hNvAGZodoM7paycD4bC8LhRBlgrivOwMDAwNC96ALDzuNPGP7//0+aAYK8bAwMDAwMO449xpCjOBAZndI3/ccluXeGL1bxvqWXGLYefki+Cx6/+EKcC9Bd4pyxGUOO4jAYFgYwMm4jWzcj4zYA2mQtm7CTmFoAAAAASUVORK5CYII="},
                {
                  label: "(5) Open Street Map Tunisia - Home",
                  url: "https://www.facebook.com/osmtun/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArUlEQVQ4jWN0yti8leH/fy8GcgAj4zYmsjUzMDAw/P/vxUS2ZihgIUYRDycrQ1aYNoOlnjgDLxcbw9yNNxiW77hNvAGZodoM7paycD4bC8LhRBlgrivOwMDAwNC96ALDzuNPGP7//0+aAYK8bAwMDAwMO449xpCjOBAZndI3/ccluXeGL1bxvqWXGLYefki+Cx6/+EKcC9Bd4pyxGUOO4jAYFgYwMm4jWzcj4zYA2mQtm7CTmFoAAAAASUVORK5CYII="},
                <h3>Data</h3>

                    {
                      label: "Liste des officines",
                      url: "http://www.dpm.tn/pharmacie/liste-des-officines"},
                    {
                      label: "Pharmacie MOUNA LAFIF EP. ANNABI GHAIET EL Tunis - med.tn",
                      url: "https://www.med.tn/pharmacie/tunis/mouna-lafif-ep.-annabi-ghaiet-el-1635.html" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACKklEQVQ4jcVSXWhScRT/n+tV2czr9JqmTlzOtTUQWwNbzBatiOiTYC8x2GNQT71EjyOIXnsYvRT0BX3AGlRUgo3oIVxtsnKu1WbBNne1/Lz3qjM/Ty8OhDkweuj3dvhxfr9zfucQ8o+A2gIRKUIIRQgpAwA2LICIqnBScAYW1/qiQla9XcOs9diMXr2GmQOA9apwMyFESgjJE0JyGwY0IrZPTPouuT98HeJzeQlK6AJQ9DYlo+AHHTsfFYtF91QwstfHpfoTv0tqRk5HHSaNBxFfAUAcJjzTd594Zo63WwyPj+3vfm7SKoWVRKZjMhAaXggnD9laddkfYq6Zksk4laLpVxFRmytV9H1mdvzy4O7rcGH0Tlyva7k9evHsNQDI1uRhHHs5c8U9Hzp8xG65NzKw64VWqYzn83nNeIAbdi8mzrus7H06mRIyI6dcz2qbCSEEAMIhQbi6z2a86ewyLQNAoUolEfFGrgTmWS49REslEkFvUPP1EjarVElCSHJT8gDip9XUe38ke4JiNYz0ZzTONnKymvUgXykr5BIQKYNevRpY4lyISP+Fxg4/xx9gZPCOctqtt/jM+h7v56WeBt1bXn8JnYvw6cpBCzsGiNj09M30SV7IdvX3tj3otlpXtmoWRZH1LsdOf4umO9t07MMz9tb5jU+Uv53yO0rlEhx19c4CQLGOs+TjwvfOWLZgUKk1cwMdhtimYILBoLz6tvVGBx+idCv+/+EPLsEAJT/XqMYAAAAASUVORK5CYII="},

                {
                  label: "(5) Omar Gaafar",
                  url: "https://www.facebook.com/geotunisia" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArUlEQVQ4jWN0yti8leH/fy8GcgAj4zYmsjUzMDAw/P/vxUS2ZihgIUYRDycrQ1aYNoOlnjgDLxcbw9yNNxiW77hNvAGZodoM7paycD4bC8LhRBlgrivOwMDAwNC96ALDzuNPGP7//0+aAYK8bAwMDAwMO449xpCjOBAZndI3/ccluXeGL1bxvqWXGLYefki+Cx6/+EKcC9Bd4pyxGUOO4jAYFgYwMm4jWzcj4zYA2mQtm7CTmFoAAAAASUVORK5CYII="},
                {
                  label: "(12) Med K Rouissi",
                  url: "https://www.facebook.com/mirekium19" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAArUlEQVQ4jWN0yti8leH/fy8GcgAj4zYmsjUzMDAw/P/vxUS2ZihgIUYRDycrQ1aYNoOlnjgDLxcbw9yNNxiW77hNvAGZodoM7paycD4bC8LhRBlgrivOwMDAwNC96ALDzuNPGP7//0+aAYK8bAwMDAwMO449xpCjOBAZndI3/ccluXeGL1bxvqWXGLYefki+Cx6/+EKcC9Bd4pyxGUOO4jAYFgYwMm4jWzcj4zYA2mQtm7CTmFoAAAAASUVORK5CYII="},
                {
                  label: "The Tale of a Mediterranean City: Mapping the History of Premodern Carthage and Tunis",
                  url: "https://storymaps-umn.opendata.arcgis.com/app/176c99de721c4b5cb573b158ac9006e5?fbclid=IwAR0ct0PDeUqOwXZ3A2g9VnBOa0l3G2LKqPFTAcS-_hvamwF6fv5NIbqHdCc" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAADt0lEQVQ4jVWTW0xbBQCG/55zeqMU2lNKS1suvXPtCmWC6CYJGYxlm8RLZkwWo8bExQdjJsleTFATjRoTE+Ob2YsmRtGo87I42USzQZ0CFRlQ2lFKL6O0pdfT6zmnPmG2/+l7+b63X4D75nK5hARfGWmW11eZdFyXI+UWlZpeU6HgZ5hS9sqtlT0A/P0OeQhOp7NDViFPq9QtgyHXS9V1ae+p2sH21E7PVOeGwvlyjpIPdRm0cpuebgwEQ4EHAjMzM9q/5669wI6NLFSPnpjcqqXerGgYJ7cdouG/Y8p1HFVlCIM9m0g1Ce4Fukm2MMSUy9f+D9j0tuf05xxr3LHi6+FvfnjKevArjASLuPkZpOIFEP8us+Onk/lRx4KF4ZSiZCw/OtT3UGsgFPiRnH5tenxxdWkKreKe8tLi06pCCDLTdE039q5ASNshfqQrqitcD46PhCnHeaM8lk7Takvvl8ndtGKou19Ckjw56b8buFCv2RkcPUdDYX0x3yAYi/Q6zNz+nz/XdRc/XhX4b9eWfmE600kKTIpDOpOza+22K/GVsIo0GNsmpS2c1vREj4Y2vgVJdsK/dfUDeWLT08QXtwm25G6TW5v1qaQApUoNFC1FdDtBifLErpgnI1R9G72v7hRtedxnHEkfySsi71sjO+tCp+04jjw+jrJgE8oOFkyZQkUsgv5JLZgmFiVvg06YYr2kznrsbKqxrzX63Se2fFEosEy8SrbbB5DY2wRhn8StBRaev/aBu2uIeUuoSSVI8hlwwhProgg2KGbHQx4UReJ0WY7uU2oILYuopiXY+nYdXv8FxHk5EikDtOph0JnbCN4IIyFpgCTskdQxcZZqRr5atg9EZDSLuuAbYHv7Efq+AGlmF17GhfzEJaAoRNrAQbMVxYEpgXvcFPTuJYtQFBQTWostovT9ztMTz866f1Pjn3e2UWmvR8/bTmgNBBDLAY06lGtRJNgsIvs8JG2aq7SMvGnq6ouSnjve5WGzckzMSHP+nKOFy/gVmvNi+GQjiG7Y0ej+HKAEkIYWUY5uQAxlxhzevzFACTYuz379KQEAxiPDH+59dsnQYZTMph59b2H5iyR8S7uolCigfAAqeBPZ/os1ru9stjNWuSz5Y5Uw91vnAEBweAqTyWBtYGvPlx8+2ZhlAseJleXe9GMX0xg845HPfWSvKo0+RWQ1I/UukHXt7a+43e6dBwIAYLFYxM0inKxw4pEiz7oKsgZGpjNfp2K+YTK6XqOajF9JVc0/zc/Ps4fOfypWo3+atpiHAAAAAElFTkSuQmCC"},

            <h3>Icons</h3>

                {
                  label: "Google {maps}",
                  url: "https://sites.google.com/site/gmapsdevelopment/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAYklEQVQ4jWN0bPmygJGRIZ6BDPD/P8NCJnI1MzAwMDAyMsSzwDh7q7hJ0uzc9pWBgYGBgYlc22EA7gKYiWQbgM8Lzm1fMeSp5oVRA4aFAYxOrV/+U+SC//8ZFpKr+f9/hoUAhkAY9u1hDxkAAAAASUVORK5CYII="},
                {
                  label: "Markers Google Maps icons | Map Icons Collection",
                  url: "http://mapicons.nicolasmollet.com/category/markers/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAX0lEQVQ4jWNkYGBgCG/885+BRLCynoWRgYGBgRGX5hV1zHB2RNNfnIYwkWozOqDYABZcEricTbQBxIQBAwMVvDBqABUMYGRgoDAvYJPEZiBMA1YXEDIEl2aCILaVsNcA12we0iGjOfgAAAAASUVORK5CYII="},

            {
              label: "Google Earth Studio",
              url: "https://earth.google.com/studio"},
            <h3>Tile Serving</h3>

                {
                  label: "Build Your Own OpenStreetMap Tile Server on Ubuntu 16.04",
                  url: "https://www.linuxbabe.com/linux-server/openstreetmap-tile-server-ubuntu-16-04"},

            {
              label: "ArcGIS - My Map",
              url: "https://www.arcgis.com/home/webmap/viewer.html?useExisting=1"},
            {
              label: "Home | OpenTopography",
              url: "https://opentopography.org/"},
            {
              label: "World maps &amp; hosting, online mapping platform – MapTiler Cloud",
              url: "https://www.maptiler.com/cloud/"},
            {
              label: "GitHub - openmaptiles/openmaptiles: OpenMapTiles Vector Tile Schema Implementation",
              url: "https://github.com/openmaptiles/openmaptiles" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACJElEQVQ4jY1TMWsUQRT+5r3d3Cbe7t3u3hEDdoJgIVieGo1YBixsBIsUtvkHNpaCnaJFUMEihSlEbGxFE8XCRrDWIAqJyd3t3JGcuduZeRa5DUtAk6968/i+733zhlE4hHq9foWZbwG4pkRmAECU2gDw1lr7Qmu9WuarUs2NOH7Onrdw2LQMa8xyO8tuA7BlA07TdM0juvA/cQHj3KdOp3MZgGUAaMTxssc8nxuzOsrzm90s2yDmU6TUtoh0BKhut9t3Rnl+1/O8Mz7z7GSlcnqwt/cKURS1pptNmW42JUmSpfGQKoCp0tBqkTZJkqWCH0VRiyaYFw/uZ+2zcbkDYFAy2AEgAOCcWymaE8yLBOZZALDOjQB0jrGCdevccH9zPEsYPxWU2uz1eutHqbXWP6DUbwCAyAwV0QDUANAxEhBE6uNaSICfAMBK1dI0vX6UOo7jeSaKsD/5F5FSa04EW+22dca8jMPw0r/ESRheZKKnxVlE3lFuzOPRMIeIWN3v93u7ux+IaBBFUasgBkFwlYi2/SD4yEQnSwaPSGv9xa/4T6abzYm6789Vq9WFMAw/K6W2CmKlUtmJoqhRTmNFHmRZ9vXgLyRx/J6IzlnnzotIX2utS/ywkaabTDQFANaY1+0su7G/0TG6WTYHpVaY6LvH/A3ATMngRCE2xtwrxADA5ViDweDNZBDsQamG7/vLw+HwDwDUajVfiZx1wMNOt3u/rPkLJe7aBdfH1TYAAAAASUVORK5CYII="},
            {
              label: "If you faced an issue like “Couldn’t connect to Docker daemon at http+docker://localunixsocket — is it running?”…",
              url: "https://medium.com/developer-space/if-you-faced-an-issue-like-couldnt-connect-to-docker-daemon-at-http-docker-localunixsocket-is-27b35f17d09d"},
            <h3>Navigation</h3>

                {
                  label: "BRouter web client",
                  url: "http://brouter.de/brouter-web/#map=7/33.984/10.811/standard"},


        {
          label: "Show and hide layers | Mapbox GL JS | Mapbox",
          url: "https://docs.mapbox.com/mapbox-gl-js/example/toggle-layers/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVQ4ja2TP2hTURjFf+flJaZkE8TFwcnBKZDqWpNIhpipuCgElL4MTjW4OXXRtYqIQgIOgU7dlA6hprg3UHAQnBz9M5fXNjfvc3hX8/rEguCZ7r3fOef7c+8VOTR7R1fMCneBmqAGYDAFptJ86/2g/DnL12JpakSzddBTYClv7BGDPZ4Mi89BljEwNSL3Dmj/RZjHzmQYdkAWAKSZc2LTF5nuBSRXMVZAL4DER9teg3zPB7myP4XH4bXxSIetrlXGIx0C1CPXFvYWCIBYmlcDP7BTPZt48Evkyq5fX3O3APaG4Q7opactmRXuBPhJZ7C9Nyh+uLFhYSM6uY/xSNhms+c6qbttZ7jLgXIGMtsFKh3lvFAVqCAuGFZtda0SKPnxmwu1MD/eROwDjF/rO7DejGYYdjAZlN4ANNfcdX+DAAT+kSz6p/gtuy+E4ZPkUnEE0OpaxWBjwWUakhp0Fg7H5ayBr4R6b7bizL0CLmfC+39eo7SaJO4jOnckZhcDY9mkm8DtXLexNK8KoBGdPARt5udxNqw/GZae/Z+nDLL0wPpAfIYwTjOnYjj1G1P863f+CZM1uINdo556AAAAAElFTkSuQmCC"},
        {
          label: "Join local JSON data with vector tile geometries | Mapbox GL JS | Mapbox",
          url: "https://docs.mapbox.com/mapbox-gl-js/example/data-join/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVQ4ja2TP2hTURjFf+flJaZkE8TFwcnBKZDqWpNIhpipuCgElL4MTjW4OXXRtYqIQgIOgU7dlA6hprg3UHAQnBz9M5fXNjfvc3hX8/rEguCZ7r3fOef7c+8VOTR7R1fMCneBmqAGYDAFptJ86/2g/DnL12JpakSzddBTYClv7BGDPZ4Mi89BljEwNSL3Dmj/RZjHzmQYdkAWAKSZc2LTF5nuBSRXMVZAL4DER9teg3zPB7myP4XH4bXxSIetrlXGIx0C1CPXFvYWCIBYmlcDP7BTPZt48Evkyq5fX3O3APaG4Q7opactmRXuBPhJZ7C9Nyh+uLFhYSM6uY/xSNhms+c6qbttZ7jLgXIGMtsFKh3lvFAVqCAuGFZtda0SKPnxmwu1MD/eROwDjF/rO7DejGYYdjAZlN4ANNfcdX+DAAT+kSz6p/gtuy+E4ZPkUnEE0OpaxWBjwWUakhp0Fg7H5ayBr4R6b7bizL0CLmfC+39eo7SaJO4jOnckZhcDY9mkm8DtXLexNK8KoBGdPARt5udxNqw/GZae/Z+nDLL0wPpAfIYwTjOnYjj1G1P863f+CZM1uINdo556AAAAAElFTkSuQmCC"},
        {
          label: "Adjust a layer&#39;s opacity | Mapbox GL JS | Mapbox",
          url: "https://docs.mapbox.com/mapbox-gl-js/example/adjust-layer-opacity/" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVQ4ja2TP2hTURjFf+flJaZkE8TFwcnBKZDqWpNIhpipuCgElL4MTjW4OXXRtYqIQgIOgU7dlA6hprg3UHAQnBz9M5fXNjfvc3hX8/rEguCZ7r3fOef7c+8VOTR7R1fMCneBmqAGYDAFptJ86/2g/DnL12JpakSzddBTYClv7BGDPZ4Mi89BljEwNSL3Dmj/RZjHzmQYdkAWAKSZc2LTF5nuBSRXMVZAL4DER9teg3zPB7myP4XH4bXxSIetrlXGIx0C1CPXFvYWCIBYmlcDP7BTPZt48Evkyq5fX3O3APaG4Q7opactmRXuBPhJZ7C9Nyh+uLFhYSM6uY/xSNhms+c6qbttZ7jLgXIGMtsFKh3lvFAVqCAuGFZtda0SKPnxmwu1MD/eROwDjF/rO7DejGYYdjAZlN4ANNfcdX+DAAT+kSz6p/gtuy+E4ZPkUnEE0OpaxWBjwWUakhp0Fg7H5ayBr4R6b7bizL0CLmfC+39eo7SaJO4jOnckZhcDY9mkm8DtXLexNK8KoBGdPARt5udxNqw/GZae/Z+nDLL0wPpAfIYwTjOnYjj1G1P863f+CZM1uINdo556AAAAAElFTkSuQmCC"},
        {
          label: "How To Set Up Vue.js Authentication and Route Handling Using vue-router | DigitalOcean",
          url: "https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router" ICON="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAACNUlEQVQ4jX3Tv4teRRTG8c+Z9+7u+8YExBQWi4VgIa6VLhpd1MVi/4MFCxFBEVGMSFCxUC5KUgQkxYIgRmvhLWxFRJeAhRAElQVFGxGVVbJCor6/7r3HYq9xbXymOXMeZubMnO8EkEEkeDUfUDyFDdyMxL70meKiOi4dXRP+0Ut5wjHnhCeFoQ7/uodxmknv+cvLzse1Pp2hdkIaG9ky0akU0FpIoVL189bQwNRHrti2E1dLX/o5I1tmZipF61OtbXOnhHstPKKxa2BgamZoy03OHlbwem7ofCIMFAOtC/a8aByto9rOgTVvGnhep5UaPFjpPGtgSUiNj9XOEGkzK5s6sKsYR0OesWZd5X5pReOJCg8j+vEWkbZzYByN3evnd32udXteVNmQUjhVdKZaP5v5wdS3h+1BnUWd5foWa7KPvtPodEJarRT3mAup870DIo21xv5fIZGVOn79j1HnMa27hUoxwWV1NPZ6KsIdKkUnpZ8qr+RJK1bN/WbZvrnbLPlQZaTxo7l123lgHK0XcqR4ur9/4FKx5FHHfWnZaXV0ioUwUgkMrfjTOFrP5HE3etvAXTqd1jWtdyrpioU9pDrv1LkVn5tbkQ4U96lzVXpOsa4xN7Js4oI34uuwmZVd6TWn3eC8ia/wkD0Ta26RLlt2UotOGgoT7zvwuB3zYldHHNpU/beZ9yQ2Qulxorhq6qxfPGYnZkRWRNe/7gemvsHv9hxivBCW/KGz3+P+rjq+ONq0vwHGVepcT9t+ZgAAAABJRU5ErkJggg=="},
      }
      copiedWay: null
    };
  },
}*/
</script>
<style lang="scss" scoped>
main {
  overflow: auto;
}
.item {
  line-height: 24px;
}
.desc {
  margin-left: 20px; 
}
img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
</style>