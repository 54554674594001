import Vue from 'vue';

const LayerPropertyEditor = Vue.component('LayerPropertyEditor', {
  props: {
    computed: Boolean,
    prop: { type: String, required: true },
    layer: { type: Object, required: true },
    spec: { type: Object, required: true }
  },
  data() {
    return {
    }
  },
  render(createElement) {
    
    const property = this.spec[this.prop];

    // current value or default
    const value = ('undefined' === typeof this.layer[this.prop])
      ? ('undefined' === typeof property.default ? null : property.default)
      : this.layer[this.prop];


    if (this.computed || (value && ('object' === typeof value))) {
      return createElement(
        'textarea',
        { 
          domProps: { value: JSON.stringify(value, null, ' ') },
          on: {
            change: (event) => {
              event.target.classList.remove('has-error');
              event.target.title = '';
              try {
                const json = JSON.parse(event.target.value);
                this.$emit('input', json);
              } catch (e) {
                console.log(e)
                event.target.title = e;
                event.target.classList.add('has-error');
              }
            }
          }
        },
      );
    }

    switch (property.type) {
      case '*':
      case 'string':
        return createElement(
          'input', 
          { 
            attrs: { type: "text" },
            domProps: { value: value },
            on: {
              input: (event) => this.$emit('input', event.target.value)
            }
          }
        );
      case 'number':
        return createElement(
          'input', 
          { 
            attrs: { type: "number", min: property.minimum, max: property.maximum }, 
            domProps: { value: value },
            on: {
              input: (event) => this.$emit('input', parseFloat(event.target.value))
            }
          }
        );
      case 'color':
        return createElement(
          'input', 
          { 
            attrs: { type: "color" }, 
            domProps: { value: value },
            on: {
              input: (event) => this.$emit('input', event.target.value)
            }
          }
        );
      case 'boolean':
        return createElement(
          'input',
          { 
            attrs: { type: "checkbox" }, 
            domProps: { checked: value },
            on: {
              change: (event) => this.$emit('input', !!event.target.checked)
            }
          }
        );
      case 'enum':
        return createElement(
          'select', 
          { 
            domProps: { value: value },
            on: {
              input: (event) => this.$emit('input', event.target.value)
            }
          },
          Object.keys(property.values).map(v => {
            return createElement('option', { attrs: { value: v } }, v)
          })
        );
      case 'formatted':
      case 'filter':
        return createElement(
          'textarea',
          { 
            domProps: { value: JSON.stringify(value, null, ' ') },
            on: {
              change: (event) => {
                event.target.classList.remove('has-error');
                event.target.title = '';
                try {
                  const json = JSON.parse(event.target.value);
                  this.$emit('input', json);
                } catch (e) {
                  console.log(e)
                  event.target.title = e;
                  event.target.classList.add('has-error');
                }
              }
            }
          },
          
        );
      default:
        return createElement( 'span', "No Editor Defined")
    }
  },
  methods: {
    renderLayout(createElement) {
      const layoutType = this.ref['layout_' + this.layer['type']];
      return createElement('table', 
        {},
        Object.keys(layoutType).map(
          f => createElement(
            'tr', 
            [
              createElement('td', f),
              createElement('td', this.layer.layout ? this.layer.layout[f] : null)
            ], 
          )
        )
      );
    },
    renderPaint(createElement) {
      const paintType = this.ref['paint_' + this.layer['type']];
      return createElement('table', 
        {},
        Object.keys(paintType).map(
          f => createElement(
            'tr', 
            [
              createElement('td', f),
              createElement('td', this.layer.paint ? this.layer.paint[f] : null)
            ], 
          )
        )
      );
    },
  }
});


export default LayerPropertyEditor;
