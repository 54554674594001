<template>
  <div id="page" class="aside-main">
    <aside>
      <ul>
        <li><a href="#formats">Les Formats</a></li>
      </ul>
    </aside>
    <main class="pad">
      <h1>Glossaire / Définitions</h1>
      <h2 id="formats">Formats de données géographiques</h2>
      <dl>
        <dt>GeoPackage <span class="extension">.gpkg</span></dt>
        <dd>GeoPackage (GPKG) est un format de données géospatiales (ou géomatiques ou SIG) raster ou vecteur, ouvert, 
          non-propriétaire, non lié à un système d'exploitation, et défini le plus possible sur la base de standards 
          OGC ou autres, implémenté sous la forme d'une base de données SQLite.<br>
          Il a été défini par l'Open Geospatial Consortium (OGC)1 avec le soutien de l'armée américaine2 et publié en 2014. <br>
          C'est le format de données par défaut dans QGIS à partir de la version 3.<br>
          Plus d'infos sur <a href="https://www.geopackage.org/" target="_blank">www.geopackage.org</a>
        </dd>
        <dt>OSM <span class="extension">.osm</span></dt>
        <dd>Format XML des données OSM. Utilisé par l'API de lecture et d'écriture.<br>
          Plus d'infos sur <a href="https://wiki.openstreetmap.org/wiki/OSM_XML" target="_blank">wiki.openstreetmap.org</a>
        </dd>
      </dl>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

dd {
  margin: 0.5em 0 1em 1em;
}

span.extension {
  background: black;
  color: #ffed00;
  font-size: 12px;
  font-family: monospace;
  padding: 2px;
  border-radius: 3px;
}
</style>