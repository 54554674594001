<template>
  <div id="page" class="aside-main">
    <aside>
      <nav>
        <ul>
          <li><a href="#data">Les Données</a></li>
          <li><a href="#todo">Todo</a></li>
        </ul>
      </nav>
    </aside>
    <main class="pad">
      <h1>Welcome to where</h1>

      <h2 id="data">Les Données</h2>
      <p>Les données présentées sur ce site proviennent 
        de <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>. </p>

      <h2 id="todo">Todo ...</h2>
      <ul class="bullets">
        <li>Nice POI glPopups</li>
        <li>Pouvoir importer ses propres données (geojson, gpx, kml, csv, xls ...)</li>
        <li>Quelques cartes thématiques (gouvernorats ...)</li>
      </ul>
    </main>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style lang="scss" scoped>

aside {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

</style>