<template>
  <div class="layer-editor">
    <div class="section">
      <div class="title" @click="toggle">Default Properties</div>
      <div>
        <div 
          v-for="(prop, propId) in defaultProperties" 
          :key="propId"
          >
          <button 
            style="float:right"
            @click="togglePropertyComputed(propId)" 
            :class="{ computed: computedProperties[propId] }"
            >f</button>
          <label :title="prop.doc">{{propId}}</label>
          <div>
            <LayerPropertyEditor 
              :spec="StyleSpec.layer" 
              :prop="propId" 
              :layer="layer"
              @input="layer[propId] = arguments[0]; $emit('layer-changed')"
              />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title" @click="toggle">Layout Properties</div>
      <div>
        <div 
          v-for="(prop, propId) in layoutProperties" 
          :key="propId"
          >
          <button 
            style="float:right"
            @click="togglePropertyComputed(propId)" 
            :class="{ computed: computedProperties[propId] }"
            >f</button>
          <label :title="prop.doc">{{propId}}</label>
          <div>
            <LayerPropertyEditor 
              :spec="StyleSpec['layout_'
               + layer.type]" 
               :prop="propId" 
               :computed="computedProperties[propId]"
               :layer="layer.layout || {}" 
               @input="layer.layout[propId] = arguments[0]; $emit('layerChanged')"
               />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title" @click="toggle">Paint Properties</div>
      <div>
        <div 
          v-for="(prop, propId) in paintProperties" 
          :key="propId"
          >
          <button 
            style="float:right"
            @click="togglePropertyComputed(propId)" 
            :class="{ computed: computedProperties[propId] }"
            >f</button>
          <label :title="prop.doc">{{propId}}</label>
          <div>
            <LayerPropertyEditor 
              :spec="StyleSpec['paint_'
               + layer.type]" 
               :prop="propId" 
               :computed="computedProperties[propId]"
               :layer="layer.paint || {}" 
               @input="layer.paint[propId] = arguments[0]; $emit('layerChanged')"
              />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title" @click="toggle">Json</div>
      <pre>{{json}}</pre>
    </div>
  </div>
</template>

<script>
import StyleSpec from "mapbox-gl/src/style-spec/reference/latest";
import LayerPropertyEditor from "@/components/mapbox/LayerPropertyEditor";

export default {
  name: 'LayerEditor',
  props: {
    layer: Object
  },
  components: {
    LayerPropertyEditor,
  },
  data() {
      return {
          StyleSpec,
          computedProperties: {}
      }
  },
  methods: {
    toggle(a) {
      const tb = a.srcElement.nextElementSibling;
      tb.style.display = (tb.style.display === 'none') ? 'block' : 'none';
    },
    togglePropertyComputed(propId) {
      this.computedProperties[propId] = !this.computedProperties[propId];
      this.computedProperties = JSON.parse(JSON.stringify(this.computedProperties));
    }
  },
  computed: {
    defaultProperties() {
      const properties = JSON.parse(JSON.stringify(this.StyleSpec.layer));
      delete properties.layout;
      delete properties.paint;
      return properties;
    },
    layoutProperties() {
      const props = {};
      for (let p in this.StyleSpec['layout_' + this.layer.type]) {
        props[p] = this.StyleSpec['layout_' + this.layer.type][p];
      }
      return props;
    },
    paintProperties() {
      const props = {};
      for (let p in this.StyleSpec['paint_' + this.layer.type]) {
        props[p] = this.StyleSpec['paint_' + this.layer.type][p];
      }
      return props;
    },
    json: {
      get() {
        return JSON.stringify(this.layer, null, ' ');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.section {
  margin-bottom: 10px;
}
.title {
  margin-bottom: 5px;
  padding: 6px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
table {
  td, th {
    border: 0;
    color: inherit;
    padding: 4px 3px;
  }
  th {
    font-weight: normal;
    width: 150px;
    text-align: left;
    background: transparent;
  }
}
.has-error {
  background-color: rgb(229, 192, 192);
}
button.computed {
  background-color: #faa;
}
</style>
