<template>
  <div class="modal-wrap">
    <div class="modal">
      <header>
        <slot name="header"></slot>
      </header>
      <main>
        <slot></slot>
      </main>
      <footer>
        <slot name="footer">
          <button @click="$emit('close')">Close</button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Modal',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";

.modal-wrap {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.4);

  display: flex;
  justify-content: center;
  align-content: center;
}

.modal {
  position: relative;
  z-index: 1001;
  width: 600px;
  min-height: 200px;
  max-height: 80%;
  background-color: #fff;
  margin: auto;

  display: grid;
  grid-template-rows: 50px 1fr 45px;

  & > * {
    padding: var(--gap);
  }

  & > main {
    border-top: 1px solid var(--borderColor);
    border-bottom: 1px solid var(--borderColor);
    overflow: auto;
  }

  & > header {
    h1 {
      font-size: 1.6rem;
      margin: 0;      
    }
    .close-icon {
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }
}

</style>
